import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n\n  .swiper {\n    height: 100%;\n    width: 100%;\n  }\n\n  .swiper-slide {\n    border-radius: 0;\n    height: auto;\n    width: ",
        ";\n  }\n\n  .swiper-pagination.swiper-pagination {\n    ",
        "\n    margin-top: ",
        ";\n  }\n\n  .swiper-horizontal > .swiper-pagination-bullets,\n  .swiper-pagination-bullets.swiper-pagination-horizontal,\n  .swiper-pagination-custom,\n  .swiper-pagination-fraction {\n    bottom: 0;\n    margin-bottom: ",
        ";\n  }\n\n  ",
        "\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  align-items: ",
        ";\n  display: flex;\n  flex-shrink: 0;\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n  background: none;\n  border: none;\n  cursor: pointer;\n  flex-shrink: 0;\n  height: ",
        ";\n  padding: 0;\n  width: ",
        ";\n\n  &:disabled {\n    cursor: not-allowed;\n\n    path {\n      stroke: ",
        ";\n    }\n  }\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import { colors, cssprops, pxToRem } from "@vfit/shared/themes";
export var SwiperWrapper = styled.div.withConfig({
    componentId: "sc-15085ad1-0"
})(_templateObject(), function(param) {
    var slideWidth = param.slideWidth;
    return pxToRem(slideWidth);
}, function(param) {
    var pagination = param.pagination;
    return !pagination ? "display: none;" : "";
}, pxToRem(28), pxToRem(-4), cssprops.styled);
export var Navigation = styled.div.withConfig({
    componentId: "sc-15085ad1-1"
})(_templateObject1(), function(param) {
    var navigationAlign = param.navigationAlign;
    return navigationAlign;
});
export var IconButton = styled.button.withConfig({
    componentId: "sc-15085ad1-2"
})(_templateObject2(), pxToRem(56), pxToRem(40), colors.$bebebe);
