import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  width: ",
        ";\n  height: ",
        ";\n  svg{\n    border-radius: ",
        ";\n  }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
import styled from "styled-components";
export var FlagsContainer = styled.div.withConfig({
    componentId: "sc-18fd2600-0"
})(_templateObject(), function(props) {
    return "".concat(props.width, "px");
}, function(props) {
    return "".concat(props.height, "px");
}, function(props) {
    return props.border ? "50%" : "0%";
});
