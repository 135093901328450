import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  margin-top: ",
        ";\n\n  > p + p {\n    margin-top: ",
        ";\n  }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  ",
        ";\n  margin: 0;\n  text-transform: capitalize;\n\n  @media (min-width: ",
        ") {\n    ",
        ";\n  }\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n  font-weight: 700;\n  ",
        ";\n  color: ",
        ";\n  margin: 0;\n\n  @media (min-width: ",
        ") {\n    ",
        ";\n  }\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import { breakpoints, colors, pxToCssFont, pxToRem } from "@vfit/shared/themes";
export var Section = styled.section.withConfig({
    componentId: "sc-2b2c4395-0"
})(_templateObject(), pxToRem(8), pxToRem(32));
export var P = styled.p.withConfig({
    componentId: "sc-2b2c4395-1"
})(_templateObject1(), pxToCssFont(20, 26), breakpoints.desktop, pxToCssFont(20, 30));
export var Street = styled.span.withConfig({
    componentId: "sc-2b2c4395-2"
})(_templateObject2(), pxToCssFont(20, 26), colors.$e60000, breakpoints.tablet, pxToCssFont(20, 30));
