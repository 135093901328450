import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  background-color: ",
        ";\n  border-radius: 20px;\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  position: relative;\n  height: 150px;\n  overflow: hidden;\n  padding-left: 0;\n  margin-bottom: 4px;\n  padding-top: 0;\n  background-repeat: no-repeat;\n  background-size: cover;\n  border-top-left-radius: 20px;\n  border-top-right-radius: 20px;\n\n  @media (min-width: ",
        ") {\n    height: 211px;\n  }\n\n  @media (min-width: ",
        ") {\n    height: 231px;\n  }\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n  img {\n    cursor: default;\n    object-fit: contain;\n\n    @media (min-width: ",
        ") {\n      padding-top: 7px;\n      padding-bottom: 4px;\n      padding-left: 32px;\n    }\n\n    padding-left: 20px;\n  }\n\n  position: absolute;\n  display: flex;\n  height: 100%;\n  top: 0;\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
function _templateObject3() {
    var data = _tagged_template_literal([
        "\n  padding: 24px 20px 24px;\n\n  @media (min-width: ",
        ") {\n    padding: 32px 40px 24px;\n  }\n\n  @media (min-width: ",
        ") {\n    padding: 34px 54px 32px 54px;\n  }\n"
    ]);
    _templateObject3 = function _templateObject3() {
        return data;
    };
    return data;
}
function _templateObject4() {
    var data = _tagged_template_literal([
        "\n  margin: 0;\n  font-weight: 400;\n  color: ",
        ";\n"
    ]);
    _templateObject4 = function _templateObject4() {
        return data;
    };
    return data;
}
function _templateObject5() {
    var data = _tagged_template_literal([
        "\n  font-family: ",
        ";\n  ",
        "\n  text-align: center;\n\n  @media (min-width: ",
        ") {\n    ",
        "\n  }\n\n  @media (min-width: ",
        ") {\n    ",
        "\n  }\n"
    ]);
    _templateObject5 = function _templateObject5() {
        return data;
    };
    return data;
}
function _templateObject6() {
    var data = _tagged_template_literal([
        "\n  margin-top: 16px;\n  font-family: ",
        ";\n  p{\n    margin: 0;\n  }\n  ",
        "\n  text-align: center;\n\n  @media (min-width: ",
        ") {\n    ",
        "\n  }\n"
    ]);
    _templateObject6 = function _templateObject6() {
        return data;
    };
    return data;
}
function _templateObject7() {
    var data = _tagged_template_literal([
        "\n  div {\n    margin: 0;\n    margin-top: 32px;\n    text-align: left;\n    font-family: ",
        ";\n    ",
        "\n    margin-bottom: 16px;\n  }\n"
    ]);
    _templateObject7 = function _templateObject7() {
        return data;
    };
    return data;
}
function _templateObject8() {
    var data = _tagged_template_literal([
        "\n  font-family: ",
        ";\n  ",
        "\n  font-weight: 700;\n\n  @media (min-width: ",
        ") {\n    ",
        "\n  }\n"
    ]);
    _templateObject8 = function _templateObject8() {
        return data;
    };
    return data;
}
function _templateObject9() {
    var data = _tagged_template_literal([
        "\n  font-family: ",
        ";\n  ",
        "\n  font-weight: 700;\n  color: ",
        ";\n\n  @media (min-width: ",
        ") {\n    ",
        "\n  }\n"
    ]);
    _templateObject9 = function _templateObject9() {
        return data;
    };
    return data;
}
function _templateObject10() {
    var data = _tagged_template_literal([
        "\n  margin-top: 16px;\n  font-family: ",
        ";\n  ",
        "\n"
    ]);
    _templateObject10 = function _templateObject10() {
        return data;
    };
    return data;
}
function _templateObject11() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  background-color: transparent;\n  padding: 0;\n  margin: 0;\n  border: none;\n  border-bottom: 1px solid ",
        ";\n  margin-top: 8px;\n  color: ",
        ";\n  font-weight: 700;\n  font-family: ",
        ";\n  ",
        ";\n\n  @media (min-width: ",
        ") {\n    ",
        "\n  }\n\n  &:hover {\n    cursor: pointer;\n    color: ",
        ";\n    border-bottom: 1px solid ",
        ";\n  }\n"
    ]);
    _templateObject11 = function _templateObject11() {
        return data;
    };
    return data;
}
function _templateObject12() {
    var data = _tagged_template_literal([
        "\n  margin-top: 36px;\n  border-bottom: ",
        ";\n"
    ]);
    _templateObject12 = function _templateObject12() {
        return data;
    };
    return data;
}
function _templateObject13() {
    var data = _tagged_template_literal([
        "\n  align-content: flex-start;\n  font-family: ",
        ";\n"
    ]);
    _templateObject13 = function _templateObject13() {
        return data;
    };
    return data;
}
function _templateObject14() {
    var data = _tagged_template_literal([
        "\n  font-family: ",
        ";\n  ",
        ";\n\n  @media (min-width: ",
        ") {\n    ",
        "\n  }\n\n  @media (min-width: ",
        ") {\n    ",
        "\n  }\n"
    ]);
    _templateObject14 = function _templateObject14() {
        return data;
    };
    return data;
}
function _templateObject15() {
    var data = _tagged_template_literal([
        "\n  font-family: ",
        ";\n  color: #262626;\n  font-weight: 700;\n  margin-top: 32px;\n  ",
        ";\n\n  "
    ]);
    _templateObject15 = function _templateObject15() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import { breakpoints, colors, fonts, pxToCssFont } from "@vfit/shared/themes";
export var Container = styled.div.withConfig({
    componentId: "sc-392f5637-0"
})(_templateObject(), colors.$ffffff);
export var TopImageContainer = styled.div.withConfig({
    componentId: "sc-392f5637-1"
})(_templateObject1(), breakpoints.tablet, breakpoints.desktop);
export var ImageContainer = styled.div.withConfig({
    componentId: "sc-392f5637-2"
})(_templateObject2(), breakpoints.desktop);
export var InnerContainer = styled.div.withConfig({
    componentId: "sc-392f5637-3"
})(_templateObject3(), breakpoints.tablet, breakpoints.desktop);
export var Paragraph = styled.p.withConfig({
    componentId: "sc-392f5637-4"
})(_templateObject4(), colors.$262626);
export var Title = styled(Paragraph).withConfig({
    componentId: "sc-392f5637-5"
})(_templateObject5(), fonts.exbold, pxToCssFont(30, 38), breakpoints.tablet, pxToCssFont(42, 52), breakpoints.desktop, pxToCssFont(36, 45));
export var Subtitle = styled(Paragraph).withConfig({
    componentId: "sc-392f5637-6"
})(_templateObject6(), fonts.regular, pxToCssFont(20, 26), breakpoints.tablet, pxToCssFont(20, 30));
export var Description = styled.div.withConfig({
    componentId: "sc-392f5637-7"
})(_templateObject7(), fonts.regular, pxToCssFont(18, 24));
export var SummaryTitle = styled(Paragraph).withConfig({
    componentId: "sc-392f5637-8"
})(_templateObject8(), fonts.regular, pxToCssFont(18, 24), breakpoints.tablet, pxToCssFont(20, 30));
export var UserDetail = styled(Paragraph).withConfig({
    componentId: "sc-392f5637-9"
})(_templateObject9(), fonts.regular, pxToCssFont(18, 24), colors.$e60000, breakpoints.tablet, pxToCssFont(20, 28));
export var LabelCta = styled(Paragraph).withConfig({
    componentId: "sc-392f5637-10"
})(_templateObject10(), fonts.regular, pxToCssFont(14, 18));
export var CtaUser = styled.button.withConfig({
    componentId: "sc-392f5637-11"
})(_templateObject11(), colors.$262626, colors.$262626, fonts.regular, pxToCssFont(16, 18), breakpoints.tablet, pxToCssFont(18, 26), colors.$e60000, colors.$e60000);
export var Divider = styled.div.withConfig({
    componentId: "sc-392f5637-12"
})(_templateObject12(), function(param) {
    var noBorder = param.noBorder;
    return noBorder ? "inherit" : "1px solid ".concat(colors.$bebebe);
});
export var OfferDetailsContainer = styled.div.withConfig({
    componentId: "sc-392f5637-13"
})(_templateObject13(), fonts.regular);
export var OfferTitle = styled(Paragraph).withConfig({
    componentId: "sc-392f5637-14"
})(_templateObject14(), fonts.exbold, pxToCssFont(30, 38), breakpoints.tablet, pxToCssFont(42, 52), breakpoints.desktop, pxToCssFont(36, 45));
export var OfferTitleSmall = styled(Paragraph).withConfig({
    componentId: "sc-392f5637-15"
})(_templateObject15(), fonts.regular, pxToCssFont(18, 18));
