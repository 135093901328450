import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  flex-direction: column;\n  margin: ",
        ";\n  row-gap: ",
        ";\n\n  & > * {\n    flex: 1;\n  }\n\n  @media (min-width: ",
        ") {\n    column-gap: ",
        ";\n    flex-direction: row;\n    justify-content: center;\n    margin: ",
        ";\n\n    & > * {\n      flex: 1;\n      max-width: ",
        ";\n    }\n  }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  align-items: center;\n  display: flex;\n  justify-content: center;\n  margin: 0;\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n  ",
        "\n  ",
        "\n  margin: ",
        ";\n  text-align: center;\n\n  @media (min-width: ",
        ") {\n    ",
        "\n  }\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
function _templateObject3() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  flex-direction: column;\n  gap: ",
        ";\n  margin: ",
        ";\n\n  @media (min-width: ",
        ") {\n    flex-direction: row;\n    justify-content: space-between;\n  }\n"
    ]);
    _templateObject3 = function _templateObject3() {
        return data;
    };
    return data;
}
function _templateObject4() {
    var data = _tagged_template_literal([
        "\n  ",
        "\n  margin: ",
        ";\n\n  @media (min-width: ",
        ") {\n    margin: ",
        ";\n  }\n"
    ]);
    _templateObject4 = function _templateObject4() {
        return data;
    };
    return data;
}
function _templateObject5() {
    var data = _tagged_template_literal([
        "\n  ",
        "\n  padding-bottom: ",
        ";\n\n  &:first-child {\n    margin-top: 0;\n  }\n\n  &:last-child {\n    margin-bottom: 0;\n  }\n\n  @media (min-width: ",
        ") {\n    padding-bottom: 0;\n  }\n"
    ]);
    _templateObject5 = function _templateObject5() {
        return data;
    };
    return data;
}
function _templateObject6() {
    var data = _tagged_template_literal([
        "\n  align-items: center;\n  background-color: ",
        ";\n  bottom: 0;\n  box-shadow: ",
        ";\n  display: flex;\n  left: 0;\n  padding: ",
        ";\n  position: absolute;\n  justify-content: center;\n  width: 100%;\n\n  @media (min-width: ",
        ") {\n    align-items: flex-start;\n    box-shadow: none;\n    padding: ",
        ";\n\n    button {\n      width: ",
        ";\n    }\n  }\n\n  @media (min-width: ",
        ") {\n    padding: ",
        ";\n  }\n"
    ]);
    _templateObject6 = function _templateObject6() {
        return data;
    };
    return data;
}
function _templateObject7() {
    var data = _tagged_template_literal([
        "\n  align-items: center;\n  display: flex;\n  flex-direction: column;\n  gap: ",
        ";\n  height: calc(\n    100% - ",
        "\n  ); // futher margin plus title height --> so we center player\n\n  justify-content: center;\n"
    ]);
    _templateObject7 = function _templateObject7() {
        return data;
    };
    return data;
}
function _templateObject8() {
    var data = _tagged_template_literal([
        "\n  width: ",
        ";\n"
    ]);
    _templateObject8 = function _templateObject8() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import { breakpoints, cssfonts, colors, pxToCssFont, pxToRem, shadows, getTypography } from "@vfit/shared/themes";
export var FilteredAppointments = styled.section.withConfig({
    componentId: "sc-450ebd5b-0"
})(_templateObject(), pxToRem(32, 0), pxToRem(16), breakpoints.tablet, pxToRem(24), pxToRem(40, 0), pxToRem(296));
export var ShowOtherAvailability = styled.p.withConfig({
    componentId: "sc-450ebd5b-1"
})(_templateObject1());
export var CalendarModalTitle = styled.h3.withConfig({
    componentId: "sc-450ebd5b-2"
})(_templateObject2(), cssfonts.exbold, pxToCssFont(28, 36), pxToRem(0, 0, 29), breakpoints.desktop, pxToCssFont(36, 48));
export var CalendarAndNoSlotsBox = styled.section.withConfig({
    componentId: "sc-450ebd5b-3"
})(_templateObject3(), pxToRem(16), pxToRem(32, 0, 0), breakpoints.tablet);
export var NoOtherSlotsTitle = styled.h3.withConfig({
    componentId: "sc-450ebd5b-4"
})(_templateObject4(), getTypography("h6.extrabold"), pxToRem(32, 0, 8), breakpoints.tablet, pxToRem(0, 0, 8));
export var NoOtherSlotsDescription = styled.div.withConfig({
    componentId: "sc-450ebd5b-5"
})(_templateObject5(), getTypography("body1.regular"), pxToRem(20 + 45 + 20), breakpoints.tablet);
export var CalendarModalFooter = styled.footer.withConfig({
    componentId: "sc-450ebd5b-6"
})(_templateObject6(), colors.$ffffff, shadows.card_overlay, pxToRem(20, 44), breakpoints.tablet, pxToRem(20, 40, 48), pxToRem(220), breakpoints.desktop, pxToRem(20, 109, 52));
export var PlayerWrap = styled.div.withConfig({
    componentId: "sc-450ebd5b-7"
})(_templateObject7(), pxToRem(24), pxToRem(72 - 49));
export var IconWrap = styled.div.withConfig({
    componentId: "sc-450ebd5b-8"
})(_templateObject8(), pxToRem(35));
export var skeletonStyle = {
    groups: [
        {
            heights: 116
        }
    ]
};
