import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  text-align: ",
        ";\n\n  img {\n    cursor: text;\n    vertical-align: baseline;\n  }\n\n  a {\n    color: ",
        ";\n    font-weight: 700;\n\n    &:hover {\n      color: ",
        ";\n    }\n  }\n\n  button {\n    font-family: ",
        ";\n    font-size: ",
        ";\n    font-weight: 400;\n    color: ",
        ";\n    background: none;\n    border: none;\n    padding: 0;\n    outline: inherit;\n\n    span {\n      font-family: ",
        ";\n      color: white;\n      background-color: ",
        ";\n      padding-left: 10px;\n      padding-right: 10px;\n      border-radius: 50%;\n      cursor: pointer;\n    }\n\n    &:hover {\n      span {\n        background-color: ",
        ";\n      }\n    }\n\n    &:active {\n      span {\n        background-color: ",
        ";\n      }\n    }\n  }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import { colors, fonts, pxToFontSize } from "@vfit/shared/themes";
export var CustomTextContainer = styled.div.withConfig({
    componentId: "sc-4efc83fd-0"
})(_templateObject(), function(param) {
    var textAlign = param.textAlign;
    return textAlign;
}, colors.$262626, colors.$e60000, fonts.exbold, function() {
    return pxToFontSize(30);
}, colors.$e60000, fonts.light, colors.$e60000, colors.$bd0000, colors.$a10000);
