import { useCallback, useId, useRef, useState } from "react";
export var usePassword = function(param) {
    var onChange = param.onChange, onFocus = param.onFocus, ref = param.ref;
    var innerRef = useRef(null);
    var ref1 = useState(false), show = ref1[0], setShow = ref1[1];
    var id = useId();
    var svg = show ? "eye" : "eyeSlash";
    var type = show ? "text" : "password";
    var refCallback = useCallback(function(node) {
        if (node) {
            innerRef.current = node;
            if (ref) {
                if (typeof ref === "function") {
                    ref(node);
                } else {
                    // eslint-disable-next-line no-param-reassign
                    ref.current = node;
                }
            }
        }
    }, [
        ref
    ]);
    var handleFocus = useCallback(function(e) {
        onFocus === null || onFocus === void 0 ? void 0 : onFocus(e);
    }, [
        onFocus
    ]);
    var toggleClick = useCallback(function() {
        setShow(function(s) {
            return !s;
        });
    }, []);
    var handleChange = useCallback(function(e) {
        onChange === null || onChange === void 0 ? void 0 : onChange(e);
    }, [
        onChange
    ]);
    return {
        handleChange: handleChange,
        handleFocus: handleFocus,
        id: id,
        refCallback: refCallback,
        svg: svg,
        toggleClick: toggleClick,
        type: type
    };
};
