import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_without_properties from "@swc/helpers/src/_object_without_properties.mjs";
import _sliced_to_array from "@swc/helpers/src/_sliced_to_array.mjs";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { getDay, getTimeRange, useDeviceType } from "@vfit/shared/hooks";
import { purify } from "@vfit/shared/themes";
import { slotsTechAppointmentSchema } from "./slotsTechAppointment.schema";
export var useSlotsTechAppointment = function(param) {
    var calendarModalButtonLabel = param.calendarModalButtonLabel, confirm = param.confirm, _dateTimes = param.dateTimes, dateTimes = _dateTimes === void 0 ? [] : _dateTimes, loading = param.loading, noOtherSlotsDescription = param.noOtherSlotsDescription, showOtherAvailabilityLinkLabel = param.showOtherAvailabilityLinkLabel, stickyOfferButtonLabel = param.stickyOfferButtonLabel, track = param.track;
    var ref = useState(false), isOpen = ref[0], setIsOpen = ref[1];
    var ref1 = useState(), slotsGroupsMaxHeight = ref1[0], setSlotsGroupsMaxHeight = ref1[1];
    var ref2 = useDeviceType(), isTablet = ref2.isTablet, isDesktop = ref2.isDesktop;
    var ref3 = _sliced_to_array(purify([
        noOtherSlotsDescription
    ]), 1), pNoOtherSlotsDescription = ref3[0];
    var ref4 = useForm({
        mode: "all",
        defaultValues: {
            calendarAppointments: ""
        }
    }), _formState = ref4.formState, isValidCalendarForm = _formState.isValid, getCalendarFormValues = ref4.getValues, registerCalendarForm = ref4.register, resetCalendarForm = ref4.reset;
    var ref5 = useForm({
        mode: "all",
        defaultValues: {
            filteredAppointments: ""
        }
    }), _formState1 = ref5.formState, isValidFilteredForm = _formState1.isValid, getFilteredFormValues = ref5.getValues, watchFilteredForm = ref5.watch, registerFilteredForm = ref5.register, resetFilteredForm = ref5.reset;
    useEffect(function() {
        if (loading && isOpen) track === null || track === void 0 ? void 0 : track("Popup disponibilit\xe0:ricalcolo", "LOADER_MODAL");
    }, [
        loading,
        isOpen
    ]);
    useEffect(function() {
        if (loading) {
            resetFilteredForm();
            resetCalendarForm();
        }
    }, [
        loading,
        resetCalendarForm,
        resetFilteredForm
    ]);
    var filteredAppointmentsId = watchFilteredForm("filteredAppointments");
    var orderedDateTimes = useMemo(function() {
        return dateTimes.map(function(_param) {
            var _validFor = _param.validFor, startDateTime = _validFor.startDateTime, endDateTime = _validFor.endDateTime, rest = _object_without_properties(_param, [
                "validFor"
            ]);
            var ref, ref1;
            return _object_spread({
                validFor: {
                    startDateTime: (ref = new Date(Number(startDateTime))) === null || ref === void 0 ? void 0 : ref.toISOString(),
                    endDateTime: (ref1 = new Date(Number(endDateTime))) === null || ref1 === void 0 ? void 0 : ref1.toISOString()
                }
            }, rest);
        }).sort(function(a, b) {
            var ref, ref1, ref2, ref3;
            var aDay = a === null || a === void 0 ? void 0 : (ref = a.validFor) === null || ref === void 0 ? void 0 : (ref1 = ref.startDateTime) === null || ref1 === void 0 ? void 0 : ref1.split("T")[0];
            var bDay = b === null || b === void 0 ? void 0 : (ref2 = b.validFor) === null || ref2 === void 0 ? void 0 : (ref3 = ref2.startDateTime) === null || ref3 === void 0 ? void 0 : ref3.split("T")[0];
            // eslint-disable-next-line no-nested-ternary
            return aDay < bDay ? -1 : aDay > bDay ? 1 : 0;
        });
    }, [
        dateTimes
    ]);
    var filteredDateTimes = useMemo(function() {
        return orderedDateTimes.slice(0, 3);
    }, [
        orderedDateTimes
    ]);
    var selectedFilteredAppointment = useMemo(function() {
        var ref, ref1;
        var slot = orderedDateTimes.find(function(param) {
            var id = param.id;
            return id === filteredAppointmentsId;
        });
        var res = {
            day: "",
            time: ""
        };
        if ((slot === null || slot === void 0 ? void 0 : (ref = slot.validFor) === null || ref === void 0 ? void 0 : ref.startDateTime) && (slot === null || slot === void 0 ? void 0 : (ref1 = slot.validFor) === null || ref1 === void 0 ? void 0 : ref1.endDateTime)) {
            var ref2, ref3, ref4;
            res = {
                day: getDay(slot === null || slot === void 0 ? void 0 : (ref2 = slot.validFor) === null || ref2 === void 0 ? void 0 : ref2.startDateTime),
                time: getTimeRange(slot === null || slot === void 0 ? void 0 : (ref3 = slot.validFor) === null || ref3 === void 0 ? void 0 : ref3.startDateTime, slot === null || slot === void 0 ? void 0 : (ref4 = slot.validFor) === null || ref4 === void 0 ? void 0 : ref4.endDateTime)
            };
        }
        return res;
    }, [
        filteredAppointmentsId,
        orderedDateTimes
    ]);
    var handleConfirm = useCallback(function(selectedEventId) {
        var selectedTimeSlot = orderedDateTimes.find(function(param) {
            var id = param.id;
            return id === selectedEventId;
        });
        if (selectedTimeSlot) {
            confirm === null || confirm === void 0 ? void 0 : confirm(selectedTimeSlot);
        }
    }, [
        confirm,
        orderedDateTimes
    ]);
    var confirmFromCalendarForm = useCallback(function() {
        track === null || track === void 0 ? void 0 : track(calendarModalButtonLabel || "", "CONFIRM_MODAL");
        var selectedEventId = getCalendarFormValues("calendarAppointments");
        handleConfirm === null || handleConfirm === void 0 ? void 0 : handleConfirm(selectedEventId);
    }, [
        calendarModalButtonLabel,
        getCalendarFormValues,
        handleConfirm,
        track
    ]);
    var confirmFromFilteredForm = useCallback(function() {
        track === null || track === void 0 ? void 0 : track(stickyOfferButtonLabel, "CONFIRM_STICKY");
        var selectedEventId = getFilteredFormValues("filteredAppointments");
        handleConfirm === null || handleConfirm === void 0 ? void 0 : handleConfirm(selectedEventId);
    }, [
        getFilteredFormValues,
        handleConfirm,
        stickyOfferButtonLabel,
        track
    ]);
    var open = useCallback(function() {
        track === null || track === void 0 ? void 0 : track(showOtherAvailabilityLinkLabel, "VIEW_MODAL");
        setIsOpen(true);
    }, [
        showOtherAvailabilityLinkLabel,
        track
    ]);
    var close = useCallback(function() {
        track === null || track === void 0 ? void 0 : track("Close Calendar Modal: X", "CLOSE_MODAL");
        setIsOpen(false);
        resetCalendarForm();
    }, [
        resetCalendarForm,
        track
    ]);
    /* Workaround to handle scrolling on calendar
   * Look at: slotsCalendarInput.style.ts
   */ useEffect(function() {
        if (isOpen) {
            var buttonHeight = 45;
            var footerPaddingTop = 20;
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            // eslint-disable-next-line consistent-return
            setTimeout(function() {
                var ref, ref1, ref2, ref3;
                var modalContent = (ref = document.getElementById("coverage-tool")) === null || ref === void 0 ? void 0 : (ref1 = ref.lastElementChild) === null || ref1 === void 0 ? void 0 : (ref2 = ref1.lastElementChild) === null || ref2 === void 0 ? void 0 : (ref3 = ref2.lastElementChild) === null || ref3 === void 0 ? void 0 : ref3.lastElementChild;
                if (modalContent) {
                    if (isTablet || isDesktop) {
                        modalContent.style.overflow = "hidden";
                        var handleSlotsGroupsMaxHeight = function() {
                            requestAnimationFrame(function() {
                                setSlotsGroupsMaxHeight(modalContent.clientHeight - buttonHeight - (isTablet ? footerPaddingTop + 88 : footerPaddingTop + 80));
                            });
                        };
                        handleSlotsGroupsMaxHeight();
                        window.addEventListener("resize", handleSlotsGroupsMaxHeight);
                        return function() {
                            window.removeEventListener("resize", handleSlotsGroupsMaxHeight);
                        };
                    }
                    modalContent.style.overflow = "auto";
                }
            }, 300);
        }
    }, [
        isOpen,
        isTablet,
        isDesktop
    ]);
    return {
        close: close,
        confirmFromCalendarForm: confirmFromCalendarForm,
        confirmFromFilteredForm: confirmFromFilteredForm,
        filteredDateTimes: filteredDateTimes,
        isOpen: isOpen,
        isValidCalendarForm: isValidCalendarForm,
        isValidFilteredForm: isValidFilteredForm,
        open: open,
        orderedDateTimes: orderedDateTimes,
        pNoOtherSlotsDescription: pNoOtherSlotsDescription,
        slotsGroupsMaxHeight: slotsGroupsMaxHeight,
        slotsTechAppointmentSchema: slotsTechAppointmentSchema,
        registerCalendarForm: registerCalendarForm,
        registerFilteredForm: registerFilteredForm,
        selectedFilteredAppointment: selectedFilteredAppointment
    };
};
