import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  ",
        "\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  padding: ",
        ";\n\n  @media (min-width: ",
        ") {\n    padding: ",
        ";\n  }\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n  ",
        "\n  ",
        "\n  margin: ",
        ";\n  text-align: center;\n\n  b {\n    ",
        "\n  }\n\n  > :first-child {\n    margin-top: 0;\n  }\n\n  > :last-child {\n    margin-bottom: 0;\n  }\n\n  @media (min-width: ",
        ") {\n    ",
        "\n  }\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
function _templateObject3() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  justify-content: center;\n  gap: ",
        ";\n\n  > * {\n    height: auto;\n  }\n"
    ]);
    _templateObject3 = function _templateObject3() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import { pxToRem, breakpoints, cssprops, cssfonts, pxToCssFont } from "@vfit/shared/themes";
export var Container = styled.section.withConfig({
    componentId: "sc-48d6d9d1-0"
})(_templateObject(), cssprops.styled);
export var Wrapper = styled.div.withConfig({
    componentId: "sc-48d6d9d1-1"
})(_templateObject1(), pxToRem(0, 32, 0), breakpoints.desktop, pxToRem(0, 80, 0));
export var Title = styled.div.withConfig({
    componentId: "sc-48d6d9d1-2"
})(_templateObject2(), cssfonts.light, pxToCssFont(28, 36), pxToRem(0, 0, 48), cssfonts.exbold, breakpoints.desktop, pxToCssFont(36, 48));
export var CardsWrapper = styled.div.withConfig({
    componentId: "sc-48d6d9d1-3"
})(_templateObject3(), pxToRem(48));
