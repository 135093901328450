import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  @media (min-width: ",
        ") {\n    align-items: flex-start;\n    flex-direction: row;\n    justify-content: space-between;\n  }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  align-items: center;\n  display: flex;\n  flex-direction: column;\n  gap: ",
        ";\n  ",
        ";\n  ",
        "\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n  ",
        "\n  ",
        "\n  text-align: center;\n\n  b {\n    ",
        "\n  }\n\n  > :first-child {\n    margin-top: 0;\n  }\n\n  > :last-child {\n    margin-bottom: 0;\n  }\n\n  @media (min-width: ",
        ") {\n    ",
        "\n    max-width: ",
        ";\n    text-align: left;\n  }\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
import styled, { css } from "styled-components";
import { breakpoints, cssfonts, cssprops, pxToCssFont, pxToRem } from "@vfit/shared/themes";
var hasTitleCss = css(_templateObject(), breakpoints.desktop);
export var Section = styled.section.withConfig({
    componentId: "sc-69fdf041-0"
})(_templateObject1(), pxToRem(32), function(param) {
    var hasTitle = param.hasTitle;
    return hasTitle ? hasTitleCss : "";
}, cssprops.styled);
export var Title = styled.div.withConfig({
    componentId: "sc-69fdf041-1"
})(_templateObject2(), cssfonts.light, pxToCssFont(28, 36), cssfonts.exbold, breakpoints.desktop, pxToCssFont(36, 48), pxToRem(514));
