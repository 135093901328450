import { useMemo } from "react";
import { useDeviceType, useMediaQuery } from "@vfit/shared/hooks";
import { colors, pxToLineHeightRem, pxToRem } from "@vfit/shared/themes";
import { useCta } from "../../cta.hook";
export var useLink = function(param) {
    var _href = param._href, _onClick = param._onClick, _onMouseEnter = param._onMouseEnter, _onMouseLeave = param._onMouseLeave, _size = param._size, disableAppMobileOpenInBrowser = param.disableAppMobileOpenInBrowser, disabled = param.disabled, variant = param.variant;
    var ref = useCta({
        _href: _href,
        _onClick: _onClick,
        _onMouseEnter: _onMouseEnter,
        _onMouseLeave: _onMouseLeave,
        disableAppMobileOpenInBrowser: disableAppMobileOpenInBrowser,
        disabled: disabled
    }), isHover = ref.isHover, href = ref.href, onClick = ref.onClick, onMouseEnter = ref.onMouseEnter, onMouseLeave = ref.onMouseLeave;
    var isDesktop = useMediaQuery("desktop", "min");
    var isMobile = useDeviceType().isMobile;
    // If 'breadcrumb' or 'white' deafult size should be 'responsive'
    var size = _size || (variant !== "regular" ? "responsive" : undefined);
    // The 'white' variant doesn't change color on hover
    var color = useMemo(function() {
        switch(true){
            case disabled:
                return colors.$7e7e7e;
            case variant === "white":
                return colors.$ffffff;
            case variant === "mva:turquoise":
                return colors.$007c92;
            case variant === "breadcrumb":
                return !isHover ? colors.$262626 : colors.$bebebe;
            case variant === "regular":
            default:
                return !isHover ? colors.$262626 : colors.$e60000;
        }
    }, [
        disabled,
        isHover,
        variant
    ]);
    var height = useMemo(function() {
        switch(_size){
            case "big":
                return pxToLineHeightRem(18, 26);
            case "responsive":
                return !isDesktop ? pxToLineHeightRem(14, 18) : pxToLineHeightRem(16, 22);
            case "responsiveMedium":
                return isMobile ? pxToRem(20) : pxToRem(24);
            case "small":
            default:
                return pxToLineHeightRem(16, 22);
        }
    }, [
        isDesktop,
        isMobile,
        _size
    ]);
    return {
        color: color,
        height: height,
        href: href,
        onClick: onClick,
        onMouseEnter: onMouseEnter,
        onMouseLeave: onMouseLeave,
        size: size
    };
};
