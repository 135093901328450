import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  position: relative;\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  appearance: none;\n  width: ",
        ";\n  height: ",
        ";\n  border: 1px solid ",
        ";\n  border-radius: 50%;\n  outline: none;\n  padding: ",
        ";\n  display: flex;\n  flex-shrink: 0;\n  justify-content: center;\n  align-items: center;\n  position: absolute;\n  margin: auto ",
        ";\n  ",
        "\n\n  &::before {\n    content: '';\n    display: block;\n    width: ",
        ";\n    height: ",
        ";\n    border-color: ",
        ";\n    border-radius: 50%;\n    transition: background-color 0.3s ease;\n  }\n\n  &:checked::before {\n    background-color: ",
        ";\n    border-color: ",
        ";\n  }\n\n  &:checked {\n    border-color: ",
        ";\n  }\n\n  &:checked + label {\n    border-color: ",
        ";\n    border-width: ",
        ";\n    padding: ",
        ";\n  }\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n  border: 1px solid ",
        ";\n  border-radius: ",
        ";\n  padding: ",
        ";\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
function _templateObject3() {
    var data = _tagged_template_literal([
        "\n  color: ",
        ";\n  ",
        "\n\n  @media (min-width: ",
        ") {\n    ",
        "\n  }\n"
    ]);
    _templateObject3 = function _templateObject3() {
        return data;
    };
    return data;
}
function _templateObject4() {
    var data = _tagged_template_literal([
        "\n  font-family: ",
        ";\n  ",
        "\n  margin:0;\n\n  @media (min-width: ",
        ") {\n    ",
        "\n  }\n\n  ",
        "\n"
    ]);
    _templateObject4 = function _templateObject4() {
        return data;
    };
    return data;
}
function _templateObject5() {
    var data = _tagged_template_literal([
        "\n  ",
        "\n  margin-top: ",
        ";\n\n  @media (min-width: ",
        ") {\n    ",
        "\n    ",
        "\n  }\n"
    ]);
    _templateObject5 = function _templateObject5() {
        return data;
    };
    return data;
}
function _templateObject6() {
    var data = _tagged_template_literal([
        "\n  ",
        "\n  font-weight: 700;\n  margin-top: ",
        ";\n  ",
        "\n  display: flex;\n  align-items: center;\n\n  @media (min-width: ",
        ") {\n    margin-top: ",
        ";\n  }\n\n  svg {\n    vertical-align: middle;\n    margin-right: ",
        ";\n  }\n"
    ]);
    _templateObject6 = function _templateObject6() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import { breakpoints, colors, fonts, pxToCssFont, pxToRem } from "@vfit/shared/themes";
export var Container = styled.div.withConfig({
    componentId: "sc-58513593-0"
})(_templateObject());
export var Input = styled.input.withConfig({
    componentId: "sc-58513593-1"
})(_templateObject1(), pxToRem(20), pxToRem(20), colors.$bebebe, pxToRem(3), pxToRem(18), function(props) {
    return props.hasDetail ? "top: ".concat(pxToRem(18), ";") : "top: calc(50% - ".concat(pxToRem(10), ");");
}, pxToRem(12), pxToRem(12), colors.$00697c, colors.$00697c, colors.$00697c, colors.$00697c, colors.$00697c, pxToRem(2), pxToRem(15, 15, 15, 55));
export var Label = styled.label.withConfig({
    componentId: "sc-58513593-2"
})(_templateObject2(), colors.$bebebe, pxToRem(16), pxToRem(16, 16, 16, 56));
export var Detail = styled.span.withConfig({
    componentId: "sc-58513593-3"
})(_templateObject3(), colors.$bebebe, pxToCssFont(16, 22), breakpoints.tablet, pxToCssFont(18, 24));
export var Title = styled.h3.withConfig({
    componentId: "sc-58513593-4"
})(_templateObject4(), fonts.exbold, pxToCssFont(20, 26), breakpoints.tablet, pxToCssFont(20, 30), function(props) {
    return props.hasDetail ? "{\n    font-family: ".concat(fonts.regular, ";\n    font-weight: 700;\n    margin-bottom: ").concat(pxToRem(5), ";\n    ").concat(pxToCssFont(16, 22), "\n    @media (min-width: ").concat(breakpoints.tablet, ") {\n      ").concat(pxToCssFont(18, 24), "\n      margin-bottom: ").concat(pxToRem(9), ";\n    }\n  }") : "";
});
export var Description = styled.span.withConfig({
    componentId: "sc-58513593-5"
})(_templateObject5(), pxToCssFont(16, 22), pxToRem(3), breakpoints.tablet, pxToCssFont(18, 24), function(props) {
    return props.hasIcon ? "margin-top: 0;" : "";
});
export var Icon = styled.span.withConfig({
    componentId: "sc-58513593-6"
})(_templateObject6(), pxToCssFont(16, 22), pxToRem(4), function(props) {
    return "color: ".concat(props.color, ";");
}, breakpoints.tablet, pxToRem(9), pxToRem(8));
