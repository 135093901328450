import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  margin: ",
        ";\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  ",
        ";\n  margin: ",
        ";\n\n  @media (min-width: ",
        ") {\n    ",
        ";\n  }\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
import { breakpoints, pxToCssFont, pxToRem } from "@vfit/shared/themes";
import styled from "styled-components";
import * as E from "../../editorialCard.style";
export var Date = styled(E.Street).withConfig({
    componentId: "sc-7281ccf4-0"
})(_templateObject(), pxToRem(0, 0, 24));
export var Description = styled.div.withConfig({
    componentId: "sc-7281ccf4-1"
})(_templateObject1(), pxToCssFont(20, 26), pxToRem(24, 0, 0), breakpoints.desktop, pxToCssFont(20, 30));
