import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  font-family: ",
        ";\n  ",
        "\n  font-weight: 400;\n  margin: ",
        ";\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  padding-right: ",
        ";\n  margin-bottom: ",
        ";\n  font-weight: 400;\n  ",
        ";\n\n  @media (min-width: ",
        ") {\n    margin: ",
        ";\n  }\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n  padding-right: 44px;\n  padding-left: 44px;\n\n  section {\n    @media (min-width: ",
        ") {\n      margin: 0 auto;\n      padding: ",
        ";\n      width: ",
        ";\n    }\n\n    @media (min-width: ",
        ") {\n      width: 100%;\n      display: flex;\n      flex-direction: column;\n      justify-content: space-between;\n\n      & > button {\n        margin: 0 auto;\n        width: ",
        ";\n      }\n    }\n  }\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
function _templateObject3() {
    var data = _tagged_template_literal([
        "\n  padding: ",
        ";\n\n  @media (min-width: ",
        ") {\n    padding: ",
        ";\n    & > div {\n      min-width: ",
        ";\n    }\n  }\n\n  @media (min-width: ",
        ") {\n    display: flex;\n    justify-content: ",
        ";\n    gap: ",
        ";\n    padding: ",
        ";\n\n    & > div {\n      min-width: ",
        ";\n    }\n  }\n"
    ]);
    _templateObject3 = function _templateObject3() {
        return data;
    };
    return data;
}
function _templateObject4() {
    var data = _tagged_template_literal([
        "\n  color: ",
        ";\n  font-weight: 700;\n"
    ]);
    _templateObject4 = function _templateObject4() {
        return data;
    };
    return data;
}
function _templateObject5() {
    var data = _tagged_template_literal([
        "\n  font-family: ",
        ";\n  font-weight: 400;\n  ",
        ";\n  margin: ",
        ";\n\n  @media (min-width: ",
        ") {\n    margin: ",
        ";\n    text-align: center;\n  }\n"
    ]);
    _templateObject5 = function _templateObject5() {
        return data;
    };
    return data;
}
function _templateObject6() {
    var data = _tagged_template_literal([
        "\n  .loading {\n    width: 100%;\n    height: 100%;\n    position: fixed;\n    top: 0;\n    right: 0;\n    bottom: 0;\n    left: 0;\n  }\n\n  @media (min-width: ",
        ") {\n    min-height: ",
        ";\n    min-width: ",
        ";\n  }\n\n  @media (min-width: ",
        ") {\n    min-height: ",
        ";\n    min-width: ",
        ";\n    padding: ",
        ";\n  }\n"
    ]);
    _templateObject6 = function _templateObject6() {
        return data;
    };
    return data;
}
import { breakpoints, colors, fonts, pxToCssFont, pxToRem } from "@vfit/shared/themes";
import styled from "styled-components";
export var Title = styled.h1.withConfig({
    componentId: "sc-4b4abfc0-0"
})(_templateObject(), fonts.exbold, pxToCssFont(30, 38), pxToRem(0, 0, 32, 0));
export var Description = styled.p.withConfig({
    componentId: "sc-4b4abfc0-1"
})(_templateObject1(), pxToRem(3), pxToRem(24), pxToCssFont(18, 24), breakpoints.desktop, pxToRem(0, 0, 40, 0));
export var ClickToCall = styled.div.withConfig({
    componentId: "sc-4b4abfc0-2"
})(_templateObject2(), breakpoints.tablet, pxToRem(0, 4, 0, 0), pxToRem(272), breakpoints.desktop, pxToRem(272));
export var InputsBox = styled.div.withConfig({
    componentId: "sc-4b4abfc0-3"
})(_templateObject3(), pxToRem(0, 0, 12, 0), breakpoints.tablet, pxToRem(0, 0, 16, 0), pxToRem(272), breakpoints.desktop, function(p) {
    return p.callMeBack ? "space-between" : "center";
}, pxToRem(32), pxToRem(0, 0, 22, 0), pxToRem(406));
export var StyledValue = styled.span.withConfig({
    componentId: "sc-4b4abfc0-4"
})(_templateObject4(), colors.$e60000);
export var FooterNote = styled.h6.withConfig({
    componentId: "sc-4b4abfc0-5"
})(_templateObject5(), fonts.regular, pxToCssFont(12, 16), pxToRem(16, 0, 0), breakpoints.tablet, pxToRem(20, 0, 0));
export var LoadingContainer = styled.div.withConfig({
    componentId: "sc-4b4abfc0-6"
})(_templateObject6(), breakpoints.tablet, pxToRem(313), pxToRem(272), breakpoints.desktop, pxToRem(313), pxToRem(406), pxToRem(0, 0, 22, 0));
