import { API, errorMock } from "@vfit/shared/data-access";
import { LoggerInstance, serverlessClient } from "@vfit/shared/providers";
import { useMutation } from "react-query";
/**
 * This method is used to aws lambda Vola Client - Check Channel api service
 * This in used in the Vola Modal component
 * Method: POST
 *
 * @returns
 */ var checkChannelService = function(id_channel, description) {
    return serverlessClient.post(API.VOLA_CLIENT, {
        id_channel: id_channel,
        data: {
            description: description
        },
        method: "check_channel"
    });
};
/**
 * This method is used to instantiate a custom useMutation hook to handle the volaClientService
 * @returns
 */ export var useCheckChannelMutation = function() {
    return useMutation([
        "checkChannelMutation"
    ], function(param) {
        var id_channel = param.id_channel, desc = param.desc;
        return checkChannelService(id_channel, desc);
    }, {
        onSuccess: function(data) {
            //queryClient.setQueryData('checkChannel', data);
            if (data) localStorage.setItem("checkChannel", JSON.stringify(data));
        },
        onError: function(error) {
            if (error) LoggerInstance.error("ERROR on checkChannel: ", errorMock("checkChannel", error));
            //queryClient.setQueryData('checkChannel', errorMock('checkChannel', error));
            if (error) localStorage.setItem("checkChannel", JSON.stringify(error));
        }
    });
};
