import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n      ",
        "\n    "
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n      ",
        "\n      flex-grow: 1;\n      text-align: center;\n    "
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n  ",
        "\n  ",
        "\n  margin: 0;\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
function _templateObject3() {
    var data = _tagged_template_literal([
        "\n  ",
        "\n  margin: 0;\n  ",
        "\n"
    ]);
    _templateObject3 = function _templateObject3() {
        return data;
    };
    return data;
}
import styled, { css } from "styled-components";
import { cssfonts, pxToCssFont } from "@vfit/shared/themes";
var variants = {
    timeRange: {
        long: css(_templateObject(), pxToCssFont(28, 36)),
        short: css(_templateObject1(), pxToCssFont(16, 22))
    }
};
export var Day = styled.h5.withConfig({
    componentId: "sc-5cf773de-0"
})(_templateObject2(), cssfonts.regular, pxToCssFont(22, 32));
export var TimeRange = styled.h4.withConfig({
    componentId: "sc-5cf773de-1"
})(_templateObject3(), cssfonts.exbold, function(param) {
    var variant = param.variant;
    return variant ? variants.timeRange[variant] : "";
});
