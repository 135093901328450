import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  flex-direction: column;\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  align-items: start;\n  margin-bottom: 24px;\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n  position: relative;\n  left: 40px;\n  top: 10px;\n  \n  svg {\n    width: 28px;\n    height: 25px;\n  }\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
import styled from "styled-components";
export var FormContainer = styled.div.withConfig({
    componentId: "sc-dc879e5f-0"
})(_templateObject());
export var AnchorContainer = styled.div.withConfig({
    componentId: "sc-dc879e5f-1"
})(_templateObject1());
export var LoadingContainer = styled.div.withConfig({
    componentId: "sc-dc879e5f-2"
})(_templateObject2());
