import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  font-family: ",
        ";\n  ",
        "\n  margin: ",
        ";\n  max-width: ",
        ";\n\n  @media (min-width: ",
        ") {\n    ",
        "\n  }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  font-size: ",
        ";\n  margin: 0;\n  max-width: ",
        ";\n\n  b {\n    color: ",
        ";\n  }\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n  height: 100%;\n  display: flex;\n  text-align: center;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  padding-inline: ",
        ";\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
function _templateObject3() {
    var data = _tagged_template_literal([
        "\n  width: ",
        ";\n"
    ]);
    _templateObject3 = function _templateObject3() {
        return data;
    };
    return data;
}
import { breakpoints, colors, fonts, pxToCssFont, pxToRem } from "@vfit/shared/themes";
import styled from "styled-components";
export var Title = styled.h1.withConfig({
    componentId: "sc-89bb07cb-0"
})(_templateObject(), fonts.exbold, pxToCssFont(30, 38), pxToRem(16, 0, 12), pxToRem(256), breakpoints.desktop, pxToCssFont(36, 45));
export var Descriprition = styled.p.withConfig({
    componentId: "sc-89bb07cb-1"
})(_templateObject1(), pxToRem(16), pxToRem(256), colors.$e60000);
export var ClickToCallCardSecondStep = styled.div.withConfig({
    componentId: "sc-89bb07cb-2"
})(_templateObject2(), pxToRem(15));
export var ImageWrapper = styled.div.withConfig({
    componentId: "sc-89bb07cb-3"
})(_templateObject3(), pxToRem(64));
