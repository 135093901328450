import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: flex-start;\n  gap: 32px;\n  align-self: stretch;\n\n  max-width: 327px;\n  margin: 64px auto;\n  background-color: ",
        ";\n\n  @media (min-width: ",
        ") {\n    max-width: 680px;\n  }\n\n  @media (min-width: ",
        ") {\n    max-width: 1062px;\n    margin: 120px auto;\n    flex-direction: row;\n  }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  flex-direction: column;\n  gap: 8px;\n  width: 100%;\n\n  div {\n    margin: 0;\n    text-align: center;\n  }\n\n  @media (min-width: ",
        ") {\n    width: 50%;\n\n    div {\n      text-align: left;\n    }\n  }\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n  width: 100%;\n  @media (min-width: ",
        ") {\n    width: 50%;\n  }\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import { breakpoints, colors } from "@vfit/shared/themes";
export var MainContainer = styled.div.withConfig({
    componentId: "sc-88b8f0e7-0"
})(_templateObject(), colors.$ffffff, breakpoints.tablet, breakpoints.desktop);
export var TextContainer = styled.div.withConfig({
    componentId: "sc-88b8f0e7-1"
})(_templateObject1(), breakpoints.desktop);
export var TableContainer = styled.div.withConfig({
    componentId: "sc-88b8f0e7-2"
})(_templateObject2(), breakpoints.desktop);
