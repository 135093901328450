import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  text-align: center;\n  margin: 25px;\n\n  @media (min-width: ",
        ") {\n    .swiper-wrapper {\n      justify-content: center;\n    }\n  }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  font-size: 28px;\n  font-weight: 400;\n  line-height: 36px;\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n  border: 1px solid #bebebe;\n  border-radius: 20px;\n  padding-inline: 24px;\n  text-align: center;\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
function _templateObject3() {
    var data = _tagged_template_literal([
        "\n  font-weight: 700;\n  font-size: 20px;\n  padding: 24px 0 16px;\n  line-height: 0;\n  /* line-height: 28px; */\n  color: #e60000;\n\n  /* margin: 25px; */\n"
    ]);
    _templateObject3 = function _templateObject3() {
        return data;
    };
    return data;
}
function _templateObject4() {
    var data = _tagged_template_literal([
        "\n  font-weight: 700;\n  font-size: 28px;\n  line-height: 0;\n  padding-bottom: 10px;\n  @media (min-width: ",
        ") {\n    font-weight: 800;\n  }\n  /* line-height: 36px; */\n"
    ]);
    _templateObject4 = function _templateObject4() {
        return data;
    };
    return data;
}
function _templateObject5() {
    var data = _tagged_template_literal([
        "\n  font-weight: 400;\n  font-size: 20px;\n  line-height: 0;\n  padding-bottom: 10px;\n\n  /* line-height: 28px; */\n"
    ]);
    _templateObject5 = function _templateObject5() {
        return data;
    };
    return data;
}
function _templateObject6() {
    var data = _tagged_template_literal([
        "\n  padding-bottom: 24px;\n  display: flex;\n  justify-content: center;\n  gap: 12px;\n"
    ]);
    _templateObject6 = function _templateObject6() {
        return data;
    };
    return data;
}
function _templateObject7() {
    var data = _tagged_template_literal([
        "\n  width: 24px;\n  height: 24px;\n"
    ]);
    _templateObject7 = function _templateObject7() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import { breakpoints } from "@vfit/shared/themes";
export var MainContainer = styled.div.withConfig({
    componentId: "sc-7952367d-0"
})(_templateObject(), breakpoints.bigDesktop);
export var Title = styled.p.withConfig({
    componentId: "sc-7952367d-1"
})(_templateObject1());
export var SmartCardUnit = styled.div.withConfig({
    componentId: "sc-7952367d-2"
})(_templateObject2());
export var UnitTitle = styled.p.withConfig({
    componentId: "sc-7952367d-3"
})(_templateObject3());
export var UnitQuota = styled.p.withConfig({
    componentId: "sc-7952367d-4"
})(_templateObject4(), breakpoints.tablet);
export var UnitLocation = styled.p.withConfig({
    componentId: "sc-7952367d-5"
})(_templateObject5());
export var UnitIcons = styled.div.withConfig({
    componentId: "sc-7952367d-6"
})(_templateObject6());
export var UnitIcon = styled.div.withConfig({
    componentId: "sc-7952367d-7"
})(_templateObject7());
