import { LoggerInstance } from "@vfit/shared/providers";
import jwtDecode from "jwt-decode";
import { getToken } from "./get-token";
export var checkJWTsValidity = function(tokenKeys) {
    /* dxlAuthTokenCB, dxlAuthToken; */ for(var i = 0; i < tokenKeys.length; i += 1){
        var token = getToken(tokenKeys[i]);
        if (token) {
            try {
                var decodedJwt = jwtDecode(token);
                console.log("auth flow: decodedJwt", decodedJwt);
                var exp = decodedJwt.exp;
                console.log("auth flow: decodedExp", exp);
                console.log("auth flow: date.now() < exp", Date.now() < exp * 1000);
                if (Date.now() < exp * 1000) return token;
            } catch (_) {
                LoggerInstance.debug("auth flow: JWT not valid");
            }
        }
    }
    return false;
};
