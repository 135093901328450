import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  &.enter {\n    animation: ",
        " 0.3s forwards;\n  }\n\n  &.exit {\n    animation: ",
        " 0.3s forwards;\n  }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n      column-gap: ",
        ";\n    "
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n      column-gap: ",
        ";\n    "
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
function _templateObject3() {
    var data = _tagged_template_literal([
        "\n  ",
        "\n  padding-bottom: ",
        ";\n  position: relative;\n  ",
        "\n"
    ]);
    _templateObject3 = function _templateObject3() {
        return data;
    };
    return data;
}
function _templateObject4() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  justify-content: center;\n"
    ]);
    _templateObject4 = function _templateObject4() {
        return data;
    };
    return data;
}
function _templateObject5() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  ",
        "\n"
    ]);
    _templateObject5 = function _templateObject5() {
        return data;
    };
    return data;
}
function _templateObject6() {
    var data = _tagged_template_literal([
        "\n  ",
        "\n  ",
        "\n  bottom: 0;\n  color: ",
        ";\n  display: flex;\n  height: ",
        ";\n  justify-content: center;\n  left: 0;\n  margin: 0;\n  position: absolute;\n  text-align: center;\n  width: 100%;\n"
    ]);
    _templateObject6 = function _templateObject6() {
        return data;
    };
    return data;
}
import styled, { css } from "styled-components";
import { animations, colors, cssprops, getTypography, mathRound, pxToRem } from "@vfit/shared/themes";
var fadeAnimation = css(_templateObject(), animations.fadeIn, animations.fadeOut);
var variants = {
    cells: {
        sunrise: css(_templateObject1(), pxToRem(8)),
        mva: css(_templateObject2(), pxToRem(16))
    }
};
export var Container = styled.div.withConfig({
    componentId: "sc-25b04eb5-0"
})(_templateObject3(), function(param) {
    var hidden = param.hidden;
    return hidden ? "visibility: hidden;" : "";
}, function(param) {
    var errorLines = param.errorLines;
    return pxToRem(errorLines && errorLines > 0 ? mathRound(mathRound(errorLines, 0) * 18) + 8 : 18 + 8);
}, cssprops.styled);
export var Wrapper = styled.div.withConfig({
    componentId: "sc-25b04eb5-1"
})(_templateObject4());
export var Cells = styled.div.withConfig({
    componentId: "sc-25b04eb5-2"
})(_templateObject5(), function(param) {
    var variant = param.variant;
    return variant ? variants.cells[variant] : "";
});
export var ErrorMessage = styled.p.withConfig({
    componentId: "sc-25b04eb5-3"
})(_templateObject6(), getTypography("footnote1.regular"), fadeAnimation, colors.$bd0000, function(param) {
    var errorLines = param.errorLines;
    return pxToRem(errorLines && errorLines > 0 ? mathRound(mathRound(errorLines, 0) * 18) : 18);
});
