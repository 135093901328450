import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  &.enter {\n    animation: ",
        " 0.8s forwards;\n  }\n\n  &.exit {\n    animation: ",
        " 0.8s forwards;\n  }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  ",
        "\n  align-items: center;\n  background-color: ",
        ";\n  display: flex;\n  justify-content: center;\n  left: 0;\n  padding: ",
        ";\n  position: fixed;\n  top: 0;\n  width: 100%;\n  z-index: 999;\n\n  @media (min-width: ",
        ") {\n    padding: ",
        ";\n  }\n\n  @media (min-width: ",
        ") {\n    padding: ",
        ";\n  }\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n  ",
        "\n  margin: 0;\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
function _templateObject3() {
    var data = _tagged_template_literal([
        "\n  height: ",
        ";\n  width: ",
        ";\n  margin-right: ",
        ";\n\n  @media (min-width: ",
        ") {\n    height: ",
        ";\n    width: ",
        ";\n  }\n"
    ]);
    _templateObject3 = function _templateObject3() {
        return data;
    };
    return data;
}
import styled, { css } from "styled-components";
import { animations, breakpoints, colors, getTypography, pxToRem } from "@vfit/shared/themes";
var fadeAnimation = css(_templateObject(), animations.fadeInDown, animations.fadeOutUp);
export var Container = styled.div.withConfig({
    componentId: "sc-409e8adb-0"
})(_templateObject1(), fadeAnimation, colors.$ffffff_80, pxToRem(20, 0), breakpoints.tablet, pxToRem(26, 0), breakpoints.desktop, pxToRem(24, 0));
export var Text = styled.h6.withConfig({
    componentId: "sc-409e8adb-1"
})(_templateObject2(), getTypography("h6.extrabold"));
export var IconWrap = styled.div.withConfig({
    componentId: "sc-409e8adb-2"
})(_templateObject3(), pxToRem(24), pxToRem(24), pxToRem(8), breakpoints.tablet, pxToRem(40), pxToRem(40));
