import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n    ",
        "\n  "
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n    ",
        "\n  "
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n    ",
        "\n\n    @media (min-width: ",
        ") {\n      ",
        "\n    }\n  "
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
function _templateObject3() {
    var data = _tagged_template_literal([
        "\n    ",
        "\n  "
    ]);
    _templateObject3 = function _templateObject3() {
        return data;
    };
    return data;
}
function _templateObject4() {
    var data = _tagged_template_literal([
        "\n    ",
        "\n\n    @media (min-width: ",
        ") {\n      ",
        "\n    }\n  "
    ]);
    _templateObject4 = function _templateObject4() {
        return data;
    };
    return data;
}
function _templateObject5() {
    var data = _tagged_template_literal([
        "\n  transition: color 0.3s ease;\n\n  & svg * {\n    transition: stroke 0.3s ease, fill 0.3s ease;\n  }\n"
    ]);
    _templateObject5 = function _templateObject5() {
        return data;
    };
    return data;
}
function _templateObject6() {
    var data = _tagged_template_literal([
        "\n    color: ",
        ";\n    font-weight: 700;\n    ",
        "\n\n    &:hover {\n      color: ",
        ";\n    }\n  "
    ]);
    _templateObject6 = function _templateObject6() {
        return data;
    };
    return data;
}
function _templateObject7() {
    var data = _tagged_template_literal([
        "\n    color: ",
        ";\n    ",
        ";\n\n    &:hover {\n      color: ",
        ";\n    }\n  "
    ]);
    _templateObject7 = function _templateObject7() {
        return data;
    };
    return data;
}
function _templateObject8() {
    var data = _tagged_template_literal([
        "\n    color: ",
        ";\n  "
    ]);
    _templateObject8 = function _templateObject8() {
        return data;
    };
    return data;
}
function _templateObject9() {
    var data = _tagged_template_literal([
        "\n    color: ",
        ";\n    text-decoration: underline;\n    text-underline-offset: 4px;\n  "
    ]);
    _templateObject9 = function _templateObject9() {
        return data;
    };
    return data;
}
function _templateObject10() {
    var data = _tagged_template_literal([
        "\n  ",
        "\n  align-items: center;\n  display: inline-flex;\n  gap: ",
        ";\n  line-height: inherit;\n  ",
        "\n\n  &::selection {\n    background-color: transparent;\n  }\n"
    ]);
    _templateObject10 = function _templateObject10() {
        return data;
    };
    return data;
}
function _templateObject11() {
    var data = _tagged_template_literal([
        "\n  color: ",
        ";\n  cursor: not-allowed;\n  pointer-events: none;\n  text-decoration: none;\n"
    ]);
    _templateObject11 = function _templateObject11() {
        return data;
    };
    return data;
}
import { css } from "styled-components";
import { breakpoints, colors, pxToCssFontSize, pxToRem } from "@vfit/shared/themes";
var sizes = {
    small: css(_templateObject(), pxToCssFontSize(16)),
    big: css(_templateObject1(), pxToCssFontSize(18)),
    responsive: css(_templateObject2(), pxToCssFontSize(14), breakpoints.desktop, pxToCssFontSize(16)),
    responsiveMedium: css(_templateObject3(), pxToCssFontSize(18)),
    responsiveBig: css(_templateObject4(), pxToCssFontSize(14), breakpoints.desktop, pxToCssFontSize(18))
};
var transition = css(_templateObject5());
var variants = {
    regular: css(_templateObject6(), colors.$262626, transition, colors.$e60000),
    breadcrumb: css(_templateObject7(), colors.$262626, transition, colors.$bebebe),
    white: css(_templateObject8(), colors.$ffffff),
    "mva:turquoise": css(_templateObject9(), colors.$007c92)
};
export var common = css(_templateObject10(), function(param) {
    var size = param.size;
    return sizes[size || "small"];
}, pxToRem(8), function(param) {
    var variant = param.variant;
    return variants[variant || "regular"];
});
// cursor: not-allowed; is ignored because pointer-events: none;
// but pointer-events: none; is necessary to disable Link from Next
export var disabled = css(_templateObject11(), colors.$7e7e7e);
