import { useEffect } from "react";
import { useAnimation } from "@vfit/shared/hooks";
export var useToast = function(param) {
    var _delay = param.delay, delay = _delay === void 0 ? 10000 : _delay, onClose = param.onClose, show = param.show;
    var ref = useAnimation({
        show: show
    }), animationClass = ref.animationClass, onAnimationEnd = ref.onAnimationEnd, shouldRender = ref.shouldRender;
    useEffect(function() {
        var timeoutID;
        if (show) {
            timeoutID = setTimeout(function() {
                onClose === null || onClose === void 0 ? void 0 : onClose();
            }, delay);
        }
        return function() {
            if (timeoutID) {
                clearTimeout(timeoutID);
            }
        };
    }, [
        delay,
        onClose,
        show
    ]);
    return {
        animationClass: animationClass,
        onAnimationEnd: onAnimationEnd,
        shouldRender: shouldRender
    };
};
