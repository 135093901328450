import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  @keyframes bounce {\n    0%,\n    20%,\n    50%,\n    80%,\n    100% {\n      transform: translateY(0);\n    }\n\n    40% {\n      transform: translateY(-20px);\n    }\n\n    60% {\n      transform: translateY(-10px);\n    }\n  }\n\n  span {\n    font-family: ",
        ";\n    font-style: normal;\n    font-weight: 700;\n    ",
        "\n    text-align: center;\n    letter-spacing: 0.5px;\n    text-transform: uppercase;\n    color: #333;\n  }\n\n  animation: ",
        ";\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  background: ",
        ";\n  border-radius: 32px;\n  padding: 10.5px 15px;\n  margin: 0;\n  cursor: ",
        ";\n  border: 1px solid ",
        ";\n  width: 100%;\n  -webkit-tap-highlight-color: transparent;\n\n  :hover {\n    @media (min-width: ",
        ") {\n      transition: background-color 400ms linear;\n      background: ",
        ";\n      border: 1px solid\n        ",
        ";\n\n      span {\n        color: ",
        ";\n      }\n    }\n  }\n\n  span {\n    color: ",
        ";\n  }\n\n  :active {\n    background: ",
        ";\n    border: 1px solid\n      ",
        ";\n\n    span {\n      color: ",
        ";\n    }\n  }\n\n  :disabled {\n    border: 1px solid ",
        ";\n    background-color: ",
        ";\n\n    span {\n      color: ",
        ";\n    }\n\n    cursor: not-allowed;\n  }\n\n  @media (hover: none) and (pointer: coarse) {\n    :hover {\n      background: ",
        ";\n      border: 1px solid ",
        ";\n\n      span {\n        color: ",
        ";\n      }\n    }\n\n    :active {\n      background: ",
        ";\n      border: 1px solid\n        ",
        ";\n\n      span {\n        color: ",
        ";\n      }\n    }\n  }\n\n  @media (min-width: ",
        ") {\n    padding: 10px 24px;\n  }\n\n  @media (min-width: ",
        ") {\n    padding: 10px 24px;\n  }\n\n  &.ButtonDefault-style-1 {\n    background: ",
        ";\n    border: ",
        ";\n    \n    :hover {\n      @media (min-width: ",
        ") {\n        background: ",
        ";\n        border: 1px solid transparent;\n\n        span {\n          color: ",
        ";\n        }\n      }\n    }\n\n    span {\n      color: ",
        ";\n    }\n\n    :active {\n      background: ",
        ";\n      border: 1px solid transparent;\n\n      span {\n        color: ",
        ";\n      }\n    }\n\n    @media (hover: none) and (pointer: coarse) {\n      :hover {\n        background: ",
        ";\n        border: 1px solid ",
        ";\n      }\n\n      :active {\n        background: ",
        ";\n        border: 1px solid ",
        ";\n      }\n    }\n  }\n\n  // this style needs to be changed with background trasparent or it's necessary to use the new button component that manage it correcty!\n  &.ButtonDefault-style-2 {\n    background: ",
        ";\n    border: 1px solid ",
        ";\n\n    :hover {\n      @media (min-width: ",
        ") {\n        background: ",
        ";\n        border: 1px solid transparent;\n\n        span {\n          color: ",
        ";\n        }\n      }\n    }\n\n    span {\n      color: ",
        ";\n    }\n\n    :active {\n      background: #7e7e7e;\n      border: 1px solid transparent;\n\n      span {\n        color: ",
        ";\n      }\n    }\n\n    @media (hover: none) and (pointer: coarse) {\n      :hover {\n        background: ",
        ";\n        border: 1px solid ",
        ";\n        span {\n          color: ",
        ";\n        }\n      }\n\n      :active {\n        background: #7e7e7e;\n        border: 1px solid #7e7e7e;\n      }\n    }\n  }\n\n  // added style 3 with white background and no border to handle components that don't yet use the new button\n  &.ButtonDefault-style-3 {\n    background: ",
        ";\n    border: none;\n\n    span {\n      color: ",
        ";\n    }\n\n    :hover {\n      background: ",
        "\n    }\n\n    :active {\n      background: ",
        ";\n    }\n\n    @media (hover: none) and (pointer: coarse) {\n      :hover {\n        background: ",
        "\n      }\n    }\n  }\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n  background: ",
        ";\n  width: ",
        ";\n  height: ",
        ";\n  border-radius: 44px;\n  border: none;\n  align-items: center;\n  justify-content: center;\n  display: flex;\n  cursor: pointer;\n  background-color: ",
        ";\n\n  :hover {\n    background: ",
        ";\n  }\n\n  :active {\n    background: ",
        ";\n  }\n\n  &.ButtonDefault-style-1 {\n    background-color: ",
        ";\n\n    :hover {\n      background: ",
        ";\n    }\n\n    :active {\n      background: ",
        ";\n    }\n  }\n\n  &.ButtonDefault-style-2,\n  &.ButtonDefault-style-3 {\n    background-color: ",
        ";\n\n    :hover {\n      background: ",
        ";\n    }\n\n    :active {\n      background: '#7E7E7E';\n    }\n  }\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
function _templateObject3() {
    var data = _tagged_template_literal([
        "\n  transform: rotate(",
        ");\n  display: flex;\n  align-items: center;\n  justify-content: center;\n\n  svg {\n    width: ",
        ";\n    height: ",
        ";\n\n    path {\n      stroke: ",
        ";\n    }\n  }\n"
    ]);
    _templateObject3 = function _templateObject3() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import { breakpoints, colors, fonts, pxToCssFont } from "@vfit/shared/themes";
export var ButtonContainer = styled.div.withConfig({
    componentId: "sc-74b79d1c-0"
})(_templateObject(), fonts.regular, pxToCssFont(14, 22), function(param) {
    var animated = param.animated;
    return animated ? "bounce 2s infinite" : "";
});
export var ButtonDefault = styled.button.withConfig({
    componentId: "sc-74b79d1c-1"
})(_templateObject1(), function(param) {
    var buttonColor = param.buttonColor, disabled = param.disabled, disabledButtonColor = param.disabledButtonColor;
    return !disabled ? buttonColor || colors.$ffffff : disabledButtonColor || "#ccc";
}, function(param) {
    var disabled = param.disabled;
    return !disabled ? "pointer" : "not-allowed";
}, function(param) {
    var borderColor = param.borderColor;
    return borderColor || colors.$ffffff;
}, breakpoints.desktop, function(param) {
    var hoverColor = param.hoverColor, buttonColor = param.buttonColor, disabled = param.disabled;
    return disabled ? "#ccc" : hoverColor || buttonColor || colors.$ffffff;
}, function(param) {
    var hoverColor = param.hoverColor, borderColor = param.borderColor;
    return hoverColor ? "transparent" : borderColor || colors.$ffffff;
}, function(param) {
    var hoverTextColor = param.hoverTextColor, labelColor = param.labelColor;
    return hoverTextColor || labelColor || colors.$262626;
}, function(param) {
    var labelColor = param.labelColor;
    return labelColor || colors.$262626;
}, function(param) {
    var clickColor = param.clickColor, buttonColor = param.buttonColor;
    return clickColor || buttonColor || colors.$ffffff;
}, function(param) {
    var clickColor = param.clickColor, borderColor = param.borderColor;
    return clickColor ? "transparent" : borderColor || colors.$ffffff;
}, function(param) {
    var clickTextColor = param.clickTextColor, labelColor = param.labelColor;
    return clickTextColor || labelColor || colors.$262626;
}, function(param) {
    var disabledBorderColor = param.disabledBorderColor;
    return disabledBorderColor || colors.$bebebe;
}, function(param) {
    var disabledButtonColor = param.disabledButtonColor;
    return disabledButtonColor || colors.$ffffff;
}, function(param) {
    var disabledTextColor = param.disabledTextColor;
    return disabledTextColor || colors.$bebebe;
}, function(param) {
    var buttonColor = param.buttonColor, disabled = param.disabled;
    return disabled ? "#ccc" : buttonColor || colors.$ffffff;
}, function(param) {
    var borderColor = param.borderColor;
    return borderColor || colors.$ffffff;
}, function(param) {
    var labelColor = param.labelColor;
    return labelColor || colors.$262626;
}, function(param) {
    var clickColor = param.clickColor, buttonColor = param.buttonColor;
    return clickColor || buttonColor || colors.$ffffff;
}, function(param) {
    var clickColor = param.clickColor, borderColor = param.borderColor;
    return clickColor || borderColor || colors.$ffffff;
}, function(param) {
    var clickTextColor = param.clickTextColor, labelColor = param.labelColor;
    return clickTextColor || labelColor || colors.$262626;
}, breakpoints.tablet, breakpoints.desktop, function(param) {
    var disabled = param.disabled;
    return !disabled ? colors.$e60000 : "#ccc";
}, function(param) {
    var disabled = param.disabled;
    return !disabled ? "1px solid ".concat(colors.$e60000) : "1px solid #ccc";
}, breakpoints.desktop, function(param) {
    var disabled = param.disabled;
    return disabled ? "#ccc" : colors.$bd0000;
}, colors.$ffffff, colors.$ffffff, colors.$a10000, colors.$ffffff, function(param) {
    var disabled = param.disabled;
    return disabled ? "#ccc" : colors.$e60000;
}, colors.$ffffff, colors.$a10000, colors.$a10000, function(param) {
    var disabled = param.disabled;
    return !disabled ? colors.$ffffff : "#ccc";
}, colors.$262626, breakpoints.desktop, function(param) {
    var disabled = param.disabled;
    return disabled ? "#ccc" : colors.$262626;
}, colors.$ffffff, colors.$262626, colors.$ffffff, function(param) {
    var disabled = param.disabled;
    return disabled ? "#ccc" : colors.$ffffff;
}, colors.$262626, colors.$262626, function(param) {
    var disabled = param.disabled;
    return !disabled ? colors.$ffffff : colors.$ffffff_60;
}, function(param) {
    var disabled = param.disabled;
    return !disabled ? colors.$262626 : colors.$bebebe;
}, colors.$ffffff_60, colors.$ffffff_80, colors.$ffffff_60);
export var ButtonSmall = styled.button.withConfig({
    componentId: "sc-74b79d1c-2"
})(_templateObject2(), colors.$ffffff, function(param) {
    var size = param.size;
    return size ? "".concat(size, "px") : "60px";
}, function(param) {
    var size = param.size;
    return size ? "".concat(size, "px") : "60px";
}, function(param) {
    var buttonColor = param.buttonColor;
    return buttonColor || colors.$ffffff;
}, function(param) {
    var hoverColor = param.hoverColor, buttonColor = param.buttonColor;
    return hoverColor || buttonColor || colors.$ffffff;
}, function(param) {
    var clickColor = param.clickColor, buttonColor = param.buttonColor;
    return clickColor || buttonColor || colors.$ffffff;
}, colors.$e60000, colors.$bd0000, colors.$a10000, colors.$ffffff, colors.$262626);
export var ArrowButton = styled.div.withConfig({
    componentId: "sc-74b79d1c-3"
})(_templateObject3(), function(param) {
    var rotation = param.rotation;
    return "".concat(rotation, "deg");
}, function(param) {
    var arrowWidth = param.arrowWidth;
    return arrowWidth ? "".concat(arrowWidth, "px") : "9px";
}, function(param) {
    var arrowHeigth = param.arrowHeigth;
    return arrowHeigth ? "".concat(arrowHeigth, "px") : "16px";
}, function(param) {
    var color = param.color;
    return color;
});
