import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  padding: 32px 40px;\n  display: flex;\n  flex-direction: column;\n  border: 1px solid ",
        ";\n  border-radius: 20px;\n  background-color: ",
        ";\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  margin-bottom: 32px;\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n  padding: ",
        ";\n  display: flex;\n  justify-content: space-between;\n  border-bottom: ",
        ";\n  div {\n    margin: 0;\n    max-width: 141px;\n    p {\n      margin: 0;\n    }\n  }\n  @media (min-width: ",
        ") {\n    div {\n      max-width: none;\n    }\n  }\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
function _templateObject3() {
    var data = _tagged_template_literal([
        "\n  text-align: center;\n  button,\n  a {\n    margin: 0 auto;\n    max-width: 320px;\n    span {\n      overflow: hidden;\n      text-overflow: ellipsis;\n      width: 100%;\n      display: block;\n    }\n  }\n  white-space: nowrap;\n"
    ]);
    _templateObject3 = function _templateObject3() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import { breakpoints, colors } from "@vfit/shared/themes";
export var Container = styled.div.withConfig({
    componentId: "sc-53f3871c-0"
})(_templateObject(), colors.$bebebe, colors.$ffffff);
export var TableContainer = styled.div.withConfig({
    componentId: "sc-53f3871c-1"
})(_templateObject1());
export var TableRow = styled.div.withConfig({
    componentId: "sc-53f3871c-2"
})(_templateObject2(), function(param) {
    var isFirstRow = param.isFirstRow, isLastRow = param.isLastRow;
    if (isFirstRow) return "0 0 16px 0";
    else if (isLastRow) return "16px 0 0 0";
    else return "16px 0";
}, function(param) {
    var isLastRow = param.isLastRow;
    return isLastRow ? "none" : "1px solid #262626";
}, breakpoints.tablet);
export var ButtonContainer = styled.div.withConfig({
    componentId: "sc-53f3871c-3"
})(_templateObject3());
