import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  &::-webkit-scrollbar {\n    width: ",
        ";\n    height: ",
        ";\n    border-radius: ",
        ";\n  }\n\n  &::-webkit-scrollbar-thumb {\n    background: ",
        ";\n    border-radius: ",
        ";\n  }\n\n  &::-webkit-scrollbar-track {\n    background: ",
        ";\n  }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  width: 100%;\n  ",
        ";\n\n  label h4 {\n    pointer-events: none;\n  }\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  flex-direction: column;\n  row-gap: ",
        ";\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
function _templateObject3() {
    var data = _tagged_template_literal([
        "\n  ",
        "\n\n  @media (min-width: ",
        ") {\n    ",
        "\n    overflow-y: auto;\n  }\n"
    ]);
    _templateObject3 = function _templateObject3() {
        return data;
    };
    return data;
}
function _templateObject4() {
    var data = _tagged_template_literal([
        "\n  ",
        "\n  margin: 0;\n\n  strong {\n    ",
        "\n  }\n\n  @media (min-width: ",
        ") {\n    text-align: center;\n  }\n"
    ]);
    _templateObject4 = function _templateObject4() {
        return data;
    };
    return data;
}
function _templateObject5() {
    var data = _tagged_template_literal([
        "\n  ",
        "\n  display: flex;\n  flex-direction: column;\n  margin-top: ",
        ";\n  row-gap: ",
        ";\n  padding: 1px;\n\n  @media (min-width: ",
        ") {\n    ",
        "\n    overflow-y: auto;\n  }\n\n  @media (min-width: ",
        ") {\n    ",
        "\n  }\n"
    ]);
    _templateObject5 = function _templateObject5() {
        return data;
    };
    return data;
}
import styled, { css } from "styled-components";
import { breakpoints, colors, cssprops, getTypography, pxToRem } from "@vfit/shared/themes";
var scrollbar = css(_templateObject(), pxToRem(4), pxToRem(18), pxToRem(20), colors.$bebebe, pxToRem(20), colors.$transparent);
export var Wrapper = styled.div.withConfig({
    componentId: "sc-6c3a0611-0"
})(_templateObject1(), cssprops.styled);
export var SlotsGroups = styled.div.withConfig({
    componentId: "sc-6c3a0611-1"
})(_templateObject2(), pxToRem(24));
export var Group = styled.div.withConfig({
    componentId: "sc-6c3a0611-2"
})(_templateObject3(), scrollbar, breakpoints.tablet, function(param) {
    var slotsGroupsMaxHeight = param.slotsGroupsMaxHeight;
    return slotsGroupsMaxHeight !== undefined ? "max-height: ".concat(pxToRem(slotsGroupsMaxHeight > 0 ? slotsGroupsMaxHeight : 0), ";") : "";
});
export var Day = styled.h4.withConfig({
    componentId: "sc-6c3a0611-3"
})(_templateObject4(), getTypography("body1.regular"), getTypography("h6.extrabold"), breakpoints.tablet);
export var Slots = styled.div.withConfig({
    componentId: "sc-6c3a0611-4"
})(_templateObject5(), scrollbar, pxToRem(12), pxToRem(8), breakpoints.tablet, function(param) {
    var slotsGroupsMaxHeight = param.slotsGroupsMaxHeight;
    return slotsGroupsMaxHeight !== undefined ? "max-height: ".concat(pxToRem(slotsGroupsMaxHeight > 64 ? slotsGroupsMaxHeight - 64 : 0), ";") : "";
}, breakpoints.desktop, function(param) {
    var slotsGroupsMaxHeight = param.slotsGroupsMaxHeight;
    return slotsGroupsMaxHeight !== undefined ? "max-height: ".concat(pxToRem(slotsGroupsMaxHeight > 68 ? slotsGroupsMaxHeight - 68 : 0), ";") : "";
});
