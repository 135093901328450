import { useDeviceType } from "@vfit/shared/hooks";
import { organizeRateSummary } from "./rateSummaryTable.oraganize";
export var useRateSummaryTable = function(countryRate) {
    var ref, ref1, ref2;
    var data = organizeRateSummary(countryRate);
    var isDataEmpty = !((ref = data[0]) === null || ref === void 0 ? void 0 : ref.title) || !((ref1 = data[0]) === null || ref1 === void 0 ? void 0 : ref1.rates[0].title) || !((ref2 = data[0]) === null || ref2 === void 0 ? void 0 : ref2.rates[0].value);
    var isDesktop = useDeviceType().isDesktop;
    return {
        data: data,
        isDataEmpty: isDataEmpty,
        isDesktop: isDesktop
    };
};
