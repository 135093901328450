import { purify } from "@vfit/shared/themes";
export var organizeClickToCallSubmitted = function(cmsData, isCallMeNow, phoneNumber, timeSlot) {
    var ref, ref1, ref2, ref3, ref4, ref5, ref6, ref7, ref8, ref9, ref10, ref11, ref12, ref13, ref14, ref15, ref16;
    return {
        successSubmission: {
            headline: ((ref2 = purify([
                (cmsData === null || cmsData === void 0 ? void 0 : (ref = cmsData.clicktocall) === null || ref === void 0 ? void 0 : (ref1 = ref.successSubmission) === null || ref1 === void 0 ? void 0 : ref1.headline) || ""
            ])) === null || ref2 === void 0 ? void 0 : ref2[0]) || "",
            img: ((ref5 = purify([
                (cmsData === null || cmsData === void 0 ? void 0 : (ref3 = cmsData.clicktocall) === null || ref3 === void 0 ? void 0 : (ref4 = ref3.successSubmission) === null || ref4 === void 0 ? void 0 : ref4.img) || ""
            ])) === null || ref5 === void 0 ? void 0 : ref5[0]) || "",
            imgMobile: ((ref8 = purify([
                (cmsData === null || cmsData === void 0 ? void 0 : (ref6 = cmsData.clicktocall) === null || ref6 === void 0 ? void 0 : (ref7 = ref6.successSubmission) === null || ref7 === void 0 ? void 0 : ref7.imgMobile) || ""
            ])) === null || ref8 === void 0 ? void 0 : ref8[0]) || "",
            message: isCallMeNow ? ((ref12 = purify([
                cmsData === null || cmsData === void 0 ? void 0 : (ref9 = cmsData.clicktocall) === null || ref9 === void 0 ? void 0 : (ref10 = ref9.successSubmission) === null || ref10 === void 0 ? void 0 : (ref11 = ref10.messageCallMeNow) === null || ref11 === void 0 ? void 0 : ref11.replace("$PHONE_NUMBER", "<b>".concat(phoneNumber, "</b>")), 
            ])) === null || ref12 === void 0 ? void 0 : ref12[0]) || "" : ((ref16 = purify([
                (cmsData === null || cmsData === void 0 ? void 0 : (ref13 = cmsData.clicktocall) === null || ref13 === void 0 ? void 0 : (ref14 = ref13.successSubmission) === null || ref14 === void 0 ? void 0 : (ref15 = ref14.message) === null || ref15 === void 0 ? void 0 : ref15.replace("$PHONE_NUMBER", "<b>".concat(phoneNumber, "</b>")).replace("$TIME", "<b>".concat(timeSlot, "</b>"))) || "", 
            ])) === null || ref16 === void 0 ? void 0 : ref16[0]) || ""
        }
    };
};
