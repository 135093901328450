import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n    display: flex;\n    flex: 1 1 0%;\n    flex-direction: column;\n    border-radius: 30px;\n    justify-content: space-between;\n    align-items: center;\n    padding: 30px;\n    background: ",
        ";\n    font-family: ",
        ";\n    color: ",
        ";\n    margin: 45px 24px 120px;\n    position: relative;\n\n    @media (min-width: ",
        ") {\n      margin: 45px 32px 120px;\n    }\n\n    @media (min-width: ",
        ") {\n      padding: 80px 65px;\n      margin: 45px 80px 120px;\n    }\n\n    @media (min-width: ",
        ") {\n      width: 1280px;\n      margin: 45px auto 120px;\n    }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  width: 100%;\n  margin-top: 32px;\n  line-height: 0;\n\n  @media (min-width: ",
        ") {\n    width: 328px;\n    margin-top: 48px;\n  }\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n  margin-bottom: 24px;\n  height: ",
        ";\n  width: ",
        ";\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
function _templateObject3() {
    var data = _tagged_template_literal([
        "\n  height: 100%;\n  width: 100%;\n  min-height: 100%;\n  min-width: 100%;\n  overflow: hidden;\n  z-index: -1;\n  object-fit: cover;\n  object-position: center;\n  border-radius: 30px;\n  position: absolute;\n  top: 0;\n  left: 0;\n"
    ]);
    _templateObject3 = function _templateObject3() {
        return data;
    };
    return data;
}
import { breakpoints, colors, fonts } from "@vfit/shared/themes";
import styled from "styled-components";
export var FullBannerTextContainer = styled.div.withConfig({
    componentId: "sc-86cd3dfe-0"
})(_templateObject(), function(param) {
    var gradient = param.gradient;
    return gradient || "";
}, fonts.regular, colors.$ffffff, breakpoints.tablet, breakpoints.desktop, breakpoints.largeDesktop);
export var CtaContainer = styled.div.withConfig({
    componentId: "sc-86cd3dfe-1"
})(_templateObject1(), breakpoints.tablet);
export var ImageContainer = styled.div.withConfig({
    componentId: "sc-86cd3dfe-2"
})(_templateObject2(), function(props) {
    return "".concat(props.iconSize ? props.iconSize : 75, "px");
}, function(props) {
    return props.iconSize ? "".concat(props.iconSize, "px") : "auto";
});
export var BackgroundImageContainer = styled.div.withConfig({
    componentId: "sc-86cd3dfe-3"
})(_templateObject3());
