import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  &,\n  &:hover,\n  &:active {\n    ",
        "\n  }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  ",
        "\n  ",
        "\n  ",
        "\n  ",
        "\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
import styled, { css } from "styled-components";
import { cssprops } from "@vfit/shared/themes";
import * as S from "../../link.style";
var _disabled = css(_templateObject(), S.disabled);
export var A = styled.a.withConfig({
    componentId: "sc-bc3309ee-0"
})(_templateObject1(), function(param) {
    var variant = param.variant;
    return variant === "regular" ? "" : "text-decoration: none;";
}, S.common, function(param) {
    var disabled = param.disabled;
    return disabled ? _disabled : "";
}, cssprops.styled);
