import { useCallback } from "react";
export var useFrameInput = function(param) {
    var name = param.name, onChange = param.onChange;
    var handleChange = useCallback(function(e) {
        document.getElementsByName(name).forEach(function(input) {
            var ref;
            if (input === e.target) {
                if (e.target.checked) {
                    var ref1;
                    (ref1 = e.target.parentElement) === null || ref1 === void 0 ? void 0 : ref1.classList.add("checked");
                } else {
                    var ref2;
                    (ref2 = e.target.parentElement) === null || ref2 === void 0 ? void 0 : ref2.classList.remove("checked");
                }
            } else if ((ref = input.parentElement) === null || ref === void 0 ? void 0 : ref.classList.contains("checked")) {
                var ref3;
                (ref3 = input.parentElement) === null || ref3 === void 0 ? void 0 : ref3.classList.remove("checked");
            }
        });
        onChange === null || onChange === void 0 ? void 0 : onChange(e);
    }, [
        name,
        onChange
    ]);
    return {
        handleChange: handleChange
    };
};
