import _async_to_generator from "@swc/helpers/src/_async_to_generator.mjs";
import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import regeneratorRuntime from "/home/jenkins/agent/workspace/web-sunrise-noprod/web-sunrise-consumer-deploy-dev/node_modules/next/dist/compiled/regenerator-runtime/runtime.js";
import { checkWindow, isAcceptedCookieLawGroup } from "@vfit/shared/data-access";
import { useEffect } from "react";
export var DatadogWrapper = function(param) {
    var beforeSend = param.beforeSend;
    useEffect(function() {
        var ref;
        if (((ref = process.env) === null || ref === void 0 ? void 0 : ref["NX_DATADOG_ENABLED"]) !== "true") return;
        if (!checkWindow() || (window === null || window === void 0 ? void 0 : window["datadog_service"]) || !isAcceptedCookieLawGroup("2")) return;
        _async_to_generator(regeneratorRuntime.mark(function _callee() {
            var ref, ref1, ref2, ref3, ref4, ref5, ref6, ref7, ref8, ref9, ref10, ref11, datadogRum, ref12, ref13, ref14, ref15, ref16, ref17, ref18, ref19;
            return regeneratorRuntime.wrap(function _callee$(_ctx) {
                while(1)switch(_ctx.prev = _ctx.next){
                    case 0:
                        ;
                        if (!(!((ref = process.env) === null || ref === void 0 ? void 0 : ref["NX_DATADOG_APPLICATION_ID"]) || !((ref1 = process.env) === null || ref1 === void 0 ? void 0 : ref1["NX_DATADOG_CLIENT_TOKEN"]))) {
                            _ctx.next = 3;
                            break;
                        }
                        return _ctx.abrupt("return");
                    case 3:
                        _ctx.next = 5;
                        return import("@datadog/browser-rum").then(function(lib) {
                            return lib.datadogRum;
                        });
                    case 5:
                        datadogRum = _ctx.sent;
                        ;
                        datadogRum.init(_object_spread({
                            applicationId: (ref2 = process.env) === null || ref2 === void 0 ? void 0 : ref2["NX_DATADOG_APPLICATION_ID"],
                            clientToken: (ref3 = process.env) === null || ref3 === void 0 ? void 0 : ref3["NX_DATADOG_CLIENT_TOKEN"],
                            site: (ref12 = (ref4 = process.env) === null || ref4 === void 0 ? void 0 : ref4["NX_DATADOG_SITE"]) !== null && ref12 !== void 0 ? ref12 : "datadoghq.eu",
                            service: (ref13 = (ref5 = process.env) === null || ref5 === void 0 ? void 0 : ref5["NX_DATADOG_SERVICE"]) !== null && ref13 !== void 0 ? ref13 : "web-landing",
                            env: (ref14 = (ref6 = process.env) === null || ref6 === void 0 ? void 0 : ref6["NX_DATADOG_ENV"]) !== null && ref14 !== void 0 ? ref14 : "test",
                            version: (ref15 = (ref7 = process.env) === null || ref7 === void 0 ? void 0 : ref7["NX_GIT_VERSION"]) !== null && ref15 !== void 0 ? ref15 : "",
                            sessionSampleRate: Number((ref16 = (ref8 = process.env) === null || ref8 === void 0 ? void 0 : ref8["NX_DATADOG_SESSION_SAMPLE"]) !== null && ref16 !== void 0 ? ref16 : 0),
                            sessionReplaySampleRate: Number((ref17 = (ref9 = process.env) === null || ref9 === void 0 ? void 0 : ref9["NX_DATADOG_SESSION_REPLAY"]) !== null && ref17 !== void 0 ? ref17 : 0),
                            trackResources: true,
                            trackLongTasks: true,
                            trackUserInteractions: (ref18 = Boolean((ref10 = process.env) === null || ref10 === void 0 ? void 0 : ref10["NX_TRACK_USER_INTERACTIONS"])) !== null && ref18 !== void 0 ? ref18 : true,
                            defaultPrivacyLevel: (ref19 = (ref11 = process.env) === null || ref11 === void 0 ? void 0 : ref11["NX_DEFAULT_PRIVACY_LEVEL"]) !== null && ref19 !== void 0 ? ref19 : "mask-user-input"
                        }, beforeSend && beforeSend));
                        window["datadog_service"] = datadogRum;
                    case 9:
                    case "end":
                        return _ctx.stop();
                }
            }, _callee);
        }))();
    }, []);
    return null;
};
