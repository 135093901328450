import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  margin-top: 32px;\n  background-color: ",
        ";\n  border-radius: 22px;\n\n  @media (min-width: ",
        ") {\n    border-radius: 26px;\n  }\n\n  @media (min-width: ",
        ") {\n    border-radius: 32px;\n  }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  position: relative;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  background-repeat: no-repeat;\n  background-size: cover;\n  height: 150px;\n  background-color: transparent;\n\n  @media (min-width: ",
        ") {\n    height: 200px;\n  }\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n  z-index: 2;\n\n  img {\n    cursor: default;\n    max-width: 212px;\n    max-height: 75px;\n    object-fit: contain;\n\n    @media (min-width: ",
        ") {\n      max-width: 317.5px;\n      max-height: 112px;\n    }\n  }\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
function _templateObject3() {
    var data = _tagged_template_literal([
        "\n  text-align: left;\n  padding: 32px 24px;\n\n  @media (min-width: ",
        ") {\n    padding: 32px 40px;\n  }\n"
    ]);
    _templateObject3 = function _templateObject3() {
        return data;
    };
    return data;
}
function _templateObject4() {
    var data = _tagged_template_literal([
        "\n  margin: 0;\n  font-weight: 400;\n  color: ",
        ";\n"
    ]);
    _templateObject4 = function _templateObject4() {
        return data;
    };
    return data;
}
function _templateObject5() {
    var data = _tagged_template_literal([
        "\n  font-family: ",
        ";\n  ",
        "\n"
    ]);
    _templateObject5 = function _templateObject5() {
        return data;
    };
    return data;
}
function _templateObject6() {
    var data = _tagged_template_literal([
        "\n  margin-top: 24px;\n  font-family: ",
        ";\n  ",
        "\n"
    ]);
    _templateObject6 = function _templateObject6() {
        return data;
    };
    return data;
}
function _templateObject7() {
    var data = _tagged_template_literal([
        "\n  margin-top: 24px;\n"
    ]);
    _templateObject7 = function _templateObject7() {
        return data;
    };
    return data;
}
function _templateObject8() {
    var data = _tagged_template_literal([
        "\n  padding: 34px 55px;\n  border-radius: 20px;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n"
    ]);
    _templateObject8 = function _templateObject8() {
        return data;
    };
    return data;
}
function _templateObject9() {
    var data = _tagged_template_literal([
        "\n  img {\n    cursor: default;\n    max-height: 151px;\n    max-width: 151px;\n  }\n"
    ]);
    _templateObject9 = function _templateObject9() {
        return data;
    };
    return data;
}
function _templateObject10() {
    var data = _tagged_template_literal([
        "\n  margin-top: 34px;\n  font-family: ",
        ";\n  ",
        "\n  text-transform: uppercase;\n"
    ]);
    _templateObject10 = function _templateObject10() {
        return data;
    };
    return data;
}
function _templateObject11() {
    var data = _tagged_template_literal([
        "\n  margin-top: 8px;\n  font-family: ",
        ";\n  ",
        "\n  color: ",
        ";\n"
    ]);
    _templateObject11 = function _templateObject11() {
        return data;
    };
    return data;
}
function _templateObject12() {
    var data = _tagged_template_literal([
        "\n  text-align: center;\n  margin-top: 8px;\n  text-transform: none;\n"
    ]);
    _templateObject12 = function _templateObject12() {
        return data;
    };
    return data;
}
function _templateObject13() {
    var data = _tagged_template_literal([
        "\n  height: 100%;\n  width: 100%;\n  min-height: 100%;\n  min-width: 100%;\n  overflow: hidden;\n  position: ",
        ";\n  top: ",
        ";\n  left: ",
        ";\n"
    ]);
    _templateObject13 = function _templateObject13() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import { breakpoints, colors, fonts, pxToCssFont } from "@vfit/shared/themes";
export var Container = styled.div.withConfig({
    componentId: "sc-7bd889b-0"
})(_templateObject(), colors.$ffffff, breakpoints.tablet, breakpoints.desktop);
export var TopImageContainer = styled.div.withConfig({
    componentId: "sc-7bd889b-1"
})(_templateObject1(), breakpoints.tablet);
export var ImageContainer = styled.div.withConfig({
    componentId: "sc-7bd889b-2"
})(_templateObject2(), breakpoints.tablet);
export var InnerContainer = styled.div.withConfig({
    componentId: "sc-7bd889b-3"
})(_templateObject3(), breakpoints.tablet);
export var Paragraph = styled.p.withConfig({
    componentId: "sc-7bd889b-4"
})(_templateObject4(), colors.$262626);
export var Title = styled(Paragraph).withConfig({
    componentId: "sc-7bd889b-5"
})(_templateObject5(), fonts.exbold, pxToCssFont(30, 38));
export var Description = styled(Paragraph).withConfig({
    componentId: "sc-7bd889b-6"
})(_templateObject6(), fonts.regular, pxToCssFont(18, 24));
export var CtaContainer = styled.div.withConfig({
    componentId: "sc-7bd889b-7"
})(_templateObject7());
export var QRContainer = styled(Container).withConfig({
    componentId: "sc-7bd889b-8"
})(_templateObject8());
export var QRCodeImg = styled.div.withConfig({
    componentId: "sc-7bd889b-9"
})(_templateObject9());
export var HeadText = styled(Paragraph).withConfig({
    componentId: "sc-7bd889b-10"
})(_templateObject10(), fonts.regular, pxToCssFont(18, 24));
export var QRTitle = styled(Paragraph).withConfig({
    componentId: "sc-7bd889b-11"
})(_templateObject11(), fonts.exbold, pxToCssFont(36, 45), colors.$e60000);
export var QRDescription = styled(HeadText).withConfig({
    componentId: "sc-7bd889b-12"
})(_templateObject12());
export var DivImgContainer = styled.div.withConfig({
    componentId: "sc-7bd889b-13"
})(_templateObject13(), function(props) {
    return props.absolute ? "absolute" : "relative";
}, function(props) {
    return props.absolute ? 0 : "";
}, function(props) {
    return props.absolute ? 0 : "";
});
