import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  ",
        "\n  cursor: ",
        ";\n  display: flex;\n  ",
        "\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  ",
        "\n  cursor: ",
        ";\n  margin-right: ",
        ";\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
import { cssprops, pxToCssFont, pxToRem } from "@vfit/shared/themes";
import styled from "styled-components";
import { checkbox } from "../../inputs.style";
export var Label = styled.label.withConfig({
    componentId: "sc-6fec91a8-0"
})(_templateObject(), pxToCssFont(14, 18), function(param) {
    var disabled = param.disabled;
    return !disabled ? "pointer" : "not-allowed";
}, cssprops.styled);
export var Input = styled.input.withConfig({
    componentId: "sc-6fec91a8-1"
})(_templateObject1(), checkbox, function(param) {
    var disabled = param.disabled;
    return !disabled ? "pointer" : "not-allowed";
}, pxToRem(8));
