import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  margin-bottom: 120px;\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  margin: 0 auto;\n  background-color: ",
        ";\n  max-width: 327px;\n\n  @media (min-width: ",
        ") {\n    max-width: 680px;\n  }\n\n  @media (min-width: ",
        ") {\n    max-width: 1062px;\n  }\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  border: 1px solid ",
        ";\n  border-radius: 20px;\n  justify-content: space-between;\n  margin-bottom: ",
        ";\n\n  @media (min-width: ",
        ") {\n    align-items: center;\n    height: 441px;\n  }\n\n  @media (min-width: ",
        ") {\n    height: 458px;\n  }\n\n  img {\n    height: inherit;\n    object-fit: cover;\n    border-top-right-radius: 20px;\n    border-bottom-right-radius: 20px;\n\n    @media (min-width: ",
        ") {\n      margin-left: 32px;\n      width: 328px;\n    }\n\n    @media (min-width: ",
        ") {\n      margin-left: 44px;\n      width: 531px !important;\n    }\n  }\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
function _templateObject3() {
    var data = _tagged_template_literal([
        "\n  padding: 32px 24px;\n\n  @media (min-width: ",
        ") {\n    padding: 40px 0;\n    padding-left: 24px;\n    width: 296px;\n  }\n\n  @media (min-width: ",
        ") {\n    padding-left: 64px;\n    width: 403px;\n  }\n"
    ]);
    _templateObject3 = function _templateObject3() {
        return data;
    };
    return data;
}
function _templateObject4() {
    var data = _tagged_template_literal([
        "\n  margin: 0;\n  font-family: ",
        ";\n  font-weight: 400;\n  ",
        "\n\n  @media (min-width: ",
        ") {\n    ",
        "\n  }\n"
    ]);
    _templateObject4 = function _templateObject4() {
        return data;
    };
    return data;
}
function _templateObject5() {
    var data = _tagged_template_literal([
        "\n  div {\n    margin: 0;\n    margin-top: 24px;\n    font-family: ",
        ";\n    font-weight: 400;\n    ",
        "\n    color: ",
        ";\n    text-align: left;\n\n    @media (min-width: ",
        ") {\n      ",
        "\n    }\n  }\n"
    ]);
    _templateObject5 = function _templateObject5() {
        return data;
    };
    return data;
}
function _templateObject6() {
    var data = _tagged_template_literal([
        "\n  margin-top: 24px;\n\n  @media (min-width: ",
        ") {\n    display: block;\n  }\n\n  @media (min-width: ",
        ") {\n    display: inline-block;\n  }\n\n  button,\n  a {\n    width: 100%;\n\n    @media (min-width: ",
        ") {\n      width: 258px;\n    }\n\n    @media (min-width: ",
        ") {\n      width: 296px;\n    }\n  }\n"
    ]);
    _templateObject6 = function _templateObject6() {
        return data;
    };
    return data;
}
function _templateObject7() {
    var data = _tagged_template_literal([
        "\n  margin-top: 44px;\n\n  div {\n    text-align: left;\n    margin: 0;\n    font-family: ",
        ";\n    font-weight: 400;\n    ",
        "\n    color: #666;\n\n    a {\n      ",
        "\n    }\n  }\n"
    ]);
    _templateObject7 = function _templateObject7() {
        return data;
    };
    return data;
}
function _templateObject8() {
    var data = _tagged_template_literal([
        "\n  div {\n    margin-bottom: 16px;\n    p {\n      margin: 0;\n    }\n  }\n"
    ]);
    _templateObject8 = function _templateObject8() {
        return data;
    };
    return data;
}
function _templateObject9() {
    var data = _tagged_template_literal([
        "\n  div {\n    ",
        "\n    font-family: ",
        ";\n    margin-bottom: 48px;\n    margin-top: 0;\n    p {\n      margin: 0;\n    }\n  }\n"
    ]);
    _templateObject9 = function _templateObject9() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import { breakpoints, fonts, pxToCssFont, colors } from "@vfit/shared/themes";
export var Container = styled.div.withConfig({
    componentId: "sc-be75798c-0"
})(_templateObject());
export var MainContainer = styled.div.withConfig({
    componentId: "sc-be75798c-1"
})(_templateObject1(), colors.$ffffff, breakpoints.tablet, breakpoints.desktop);
export var CardContainer = styled.div.withConfig({
    componentId: "sc-be75798c-2"
})(_templateObject2(), colors.$bebebe, function(param) {
    var isLastCard = param.isLastCard;
    return isLastCard ? 0 : "48px";
}, breakpoints.tablet, breakpoints.desktop, breakpoints.tablet, breakpoints.desktop);
export var DescriptionBox = styled.div.withConfig({
    componentId: "sc-be75798c-3"
})(_templateObject3(), breakpoints.tablet, breakpoints.desktop);
export var Title = styled.p.withConfig({
    componentId: "sc-be75798c-4"
})(_templateObject4(), fonts.exbold, pxToCssFont(30, 38), breakpoints.desktop, pxToCssFont(36, 45));
export var Description = styled.div.withConfig({
    componentId: "sc-be75798c-5"
})(_templateObject5(), fonts.regular, pxToCssFont(18, 24), colors.$262626, breakpoints.desktop, pxToCssFont(18, 24));
export var CtaContainer = styled.div.withConfig({
    componentId: "sc-be75798c-6"
})(_templateObject6(), breakpoints.tablet, breakpoints.desktop, breakpoints.tablet, breakpoints.desktop);
export var Info = styled.div.withConfig({
    componentId: "sc-be75798c-7"
})(_templateObject7(), fonts.regular, pxToCssFont(14, 18), pxToCssFont(16, 22));
export var TitleContainer = styled.div.withConfig({
    componentId: "sc-be75798c-8"
})(_templateObject8());
export var DescriptionContainer = styled.div.withConfig({
    componentId: "sc-be75798c-9"
})(_templateObject9(), pxToCssFont(18, 24), fonts.regular);
