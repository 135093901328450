export var errorMock = function(apiCall, error, errorType, errorCode, errorMessage) {
    var ref, ref1, ref2, ref3;
    return {
        error: {
            url: ((ref3 = (ref2 = error === null || error === void 0 ? void 0 : (ref = error.url) === null || ref === void 0 ? void 0 : ref.substring(error.url.lastIndexOf("/") + 1, error === null || error === void 0 ? void 0 : (ref1 = error.url) === null || ref1 === void 0 ? void 0 : ref1.length)) === null || ref2 === void 0 ? void 0 : ref2.split("?")) === null || ref3 === void 0 ? void 0 : ref3[0]) || apiCall || "",
            status: (error === null || error === void 0 ? void 0 : error.status) || "timeout",
            statusText: (error === null || error === void 0 ? void 0 : error.statusText) || "timeout"
        },
        errorMessage: errorMessage || "Error occurred in ".concat(apiCall, " API service"),
        errorType: errorType,
        errorCode: errorCode
    };
};
