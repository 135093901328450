export var slotsTechAppointmentSchema = {
    calendarAppointments: {
        required: {
            value: true,
            message: "Campo obbligatorio"
        }
    },
    filteredAppointments: {
        required: {
            value: true,
            message: "Campo obbligatorio"
        }
    }
};
