import _sliced_to_array from "@swc/helpers/src/_sliced_to_array.mjs";
import { useDeviceType } from "@vfit/shared/hooks";
import { purify } from "@vfit/shared/themes";
export var useEditorialCardsSwiper = function(param) {
    var title = param.title;
    var isMobile = useDeviceType().isMobile;
    var ref = _sliced_to_array(purify([
        title
    ]), 1), pTitle = ref[0];
    return {
        isMobile: isMobile,
        pTitle: pTitle
    };
};
