import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  flex-direction: column;\n  align-items: left;\n  width: 100%;\n  border-radius: 20px;\n  padding: 32px 24px;\n  gap: 32px;\n  background: ",
        ";\n  border: 1px solid\n    ",
        ";\n\n  @media (min-width: ",
        ") {\n    gap: 24px;\n  }\n\n  @media (min-width: ",
        ") {\n    padding: 32px 64px;\n    gap: 24px;\n  }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  flex-direction: column;\n  gap: 16px;\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n  margin: 0;\n  font-family: ",
        ";\n  font-weight: 800;\n  ",
        ";\n  color: ",
        ";\n\n  p {\n    margin: 0;\n  }\n\n  @media (min-width: ",
        ") {\n    ",
        ";\n  }\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
function _templateObject3() {
    var data = _tagged_template_literal([
        "\n  margin: 0;\n  font-family: ",
        ";\n  font-weight: 400;\n  ",
        ";\n  color: ",
        ";\n  \n  p {\n    margin: 0;\n  }\n\n  @media (min-width: ",
        ") {\n    ",
        ";\n  }\n"
    ]);
    _templateObject3 = function _templateObject3() {
        return data;
    };
    return data;
}
import { breakpoints, colors, fonts, pxToCssFont } from "@vfit/shared/themes";
import styled from "styled-components";
export var Container = styled.div.withConfig({
    componentId: "sc-7b1ef59c-0"
})(_templateObject(), function(param) {
    var background = param.background;
    return background && background.isGradient ? "linear-gradient(".concat(background.angle, "deg, ").concat(background.firstColor, ", ").concat(background.secondColor, ")") : (background === null || background === void 0 ? void 0 : background.firstColor) || colors.$ffffff;
}, function(param) {
    var background = param.background;
    return background && background.isGradient ? "linear-gradient(".concat(background.angle, "deg, ").concat(background.firstColor, ", ").concat(background.secondColor, ")") : (background === null || background === void 0 ? void 0 : background.firstColor) || colors.$262626;
}, breakpoints.tablet, breakpoints.desktop);
export var TextDiv = styled.div.withConfig({
    componentId: "sc-7b1ef59c-1"
})(_templateObject1());
export var Title = styled.h3.withConfig({
    componentId: "sc-7b1ef59c-2"
})(_templateObject2(), fonts.exbold, pxToCssFont(28, 36), function(param) {
    var textColor = param.textColor;
    return textColor || colors.$262626;
}, breakpoints.desktop, pxToCssFont(36, 48));
export var Description = styled.div.withConfig({
    componentId: "sc-7b1ef59c-3"
})(_templateObject3(), fonts.regular, pxToCssFont(20, 28), function(param) {
    var textColor = param.textColor;
    return textColor || colors.$262626;
}, breakpoints.desktop, pxToCssFont(22, 32));
