import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  border: 1.5px solid ",
        ";\n  padding: ",
        ";\n\n  &:first-of-type {\n    border-radius: ",
        ";\n    border-bottom: none;\n  }\n\n  &:last-of-type {\n    border-radius: ",
        ";\n    border-top: none;\n  }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  font-family: ",
        ";\n  font-weight: 800;\n  font-size: ",
        ";\n  margin: ",
        ";\n  line-height: ",
        ";\n  text-align: center;\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n  align-items: center;\n  color: ",
        ";\n  display: flex;\n  font-family: ",
        ";\n  font-weight: 700;\n  justify-content: space-between;\n  margin: ",
        " !important;\n\n  @media (min-width: ",
        ") {\n    width: unset;\n    height: ",
        ";\n  }\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
function _templateObject3() {
    var data = _tagged_template_literal([
        "\n  font-size: ",
        ";\n  line-height: ",
        ";\n  margin: 0;\n  text-transform: uppercase;\n  font-weight: 400;\n  width: ",
        ";\n\n  @media (min-width: ",
        ") {\n    width: unset;\n  }\n"
    ]);
    _templateObject3 = function _templateObject3() {
        return data;
    };
    return data;
}
function _templateObject4() {
    var data = _tagged_template_literal([
        "\n  font-size: ",
        ";\n"
    ]);
    _templateObject4 = function _templateObject4() {
        return data;
    };
    return data;
}
function _templateObject5() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  flex-direction: column;\n"
    ]);
    _templateObject5 = function _templateObject5() {
        return data;
    };
    return data;
}
function _templateObject6() {
    var data = _tagged_template_literal([
        "\n  border: 1.5px solid ",
        ";\n  border-radius: ",
        ";\n  overflow: hidden;\n"
    ]);
    _templateObject6 = function _templateObject6() {
        return data;
    };
    return data;
}
function _templateObject7() {
    var data = _tagged_template_literal([
        "\n  align-items: center;\n  background-color: ",
        ";\n  display: flex;\n  justify-content: center;\n  text-transform: uppercase;\n  font-family: ",
        ";\n  font-weight: 400;\n  font-size: ",
        ";\n  line-height: ",
        ";\n"
    ]);
    _templateObject7 = function _templateObject7() {
        return data;
    };
    return data;
}
function _templateObject8() {
    var data = _tagged_template_literal([
        "\n  align-items: center;\n  display: flex;\n  min-height: ",
        ";\n  justify-content: center;\n  width: ",
        ";\n  padding: ",
        ";\n\n  :first-child {\n    font-family: ",
        ";\n    font-size: ",
        ";\n    font-weight: 800;\n    line-height: ",
        ";\n    margin: 0;\n    min-width: ",
        ";\n    width: ",
        ";\n  }\n"
    ]);
    _templateObject8 = function _templateObject8() {
        return data;
    };
    return data;
}
function _templateObject9() {
    var data = _tagged_template_literal([
        "\n  &&& {\n    text-transform: uppercase;\n    font-family: ",
        ";\n    font-weight: 700;\n    font-size: ",
        ";\n    line-height: ",
        ";\n    justify-content: flex-start;\n  }\n"
    ]);
    _templateObject9 = function _templateObject9() {
        return data;
    };
    return data;
}
function _templateObject10() {
    var data = _tagged_template_literal([
        ""
    ]);
    _templateObject10 = function _templateObject10() {
        return data;
    };
    return data;
}
function _templateObject11() {
    var data = _tagged_template_literal([
        "\n  align-items: center;\n  border-bottom: 1.5px solid ",
        ";\n  display: flex;\n  font-family: ",
        ";\n  font-weight: 400;\n  font-size: ",
        ";\n  line-height: ",
        ";\n\n  :first-child {\n    min-width: ",
        ";\n  }\n"
    ]);
    _templateObject11 = function _templateObject11() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import { breakpoints, colors, fonts, pxToRem } from "@vfit/shared/themes";
// MOBILE TABLET
export var CountrySectionInNoDesktop = styled.section.withConfig({
    componentId: "sc-1f43e33d-0"
})(_templateObject(), colors.$bebebe, pxToRem(24), pxToRem(12, 12, 0, 0), pxToRem(0, 0, 12, 12));
export var CountryTitleNoDesktop = styled.h2.withConfig({
    componentId: "sc-1f43e33d-1"
})(_templateObject1(), fonts.vodafone, pxToRem(24), pxToRem(0, 0, 16), pxToRem(32));
export var DataNoDesktopRow = styled.div.withConfig({
    componentId: "sc-1f43e33d-2"
})(_templateObject2(), colors.$262626, fonts.regular, pxToRem(0, 0, 16), breakpoints.tablet, pxToRem(24));
export var DataTypeNoDesktop = styled.h3.withConfig({
    componentId: "sc-1f43e33d-3"
})(_templateObject3(), pxToRem(18), pxToRem(24), pxToRem(127), breakpoints.tablet);
export var DataValueNoDesktop = styled.span.withConfig({
    componentId: "sc-1f43e33d-4"
})(_templateObject4(), pxToRem(18));
export var RateSummaryTableNoDesktop = styled.div.withConfig({
    componentId: "sc-1f43e33d-5"
})(_templateObject5());
// DESKTOP
export var TableContainerInDesktop = styled.div.withConfig({
    componentId: "sc-1f43e33d-6"
})(_templateObject6(), colors.$bebebe, pxToRem(16));
export var TableHeaderDesktop = styled.header.withConfig({
    componentId: "sc-1f43e33d-7"
})(_templateObject7(), colors.$f2f2f2, fonts.regular, pxToRem(18), pxToRem(25));
export var CellDesktop = styled.div.withConfig({
    componentId: "sc-1f43e33d-8"
})(_templateObject8(), pxToRem(80), pxToRem(117), pxToRem(28, 16), fonts.vodafone, pxToRem(22), pxToRem(32), pxToRem(150), pxToRem(127));
export var TopLeftCorner = styled(CellDesktop).withConfig({
    componentId: "sc-1f43e33d-9"
})(_templateObject9(), fonts.vodafone, pxToRem(18), pxToRem(24));
export var TableBodyDesktop = styled.main.withConfig({
    componentId: "sc-1f43e33d-10"
})(_templateObject10());
export var TableBodyRow = styled.div.withConfig({
    componentId: "sc-1f43e33d-11"
})(_templateObject11(), colors.$bebebe, fonts.vodafone, pxToRem(22), pxToRem(32), pxToRem(150));
