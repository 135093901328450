import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n      ",
        "\n      ",
        "\n      border-color: ",
        ";\n      width: ",
        ";\n    "
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n      ",
        "\n      ",
        "\n      border-color: ",
        ";\n      width: ",
        ";\n      ",
        "\n    "
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n  border-radius: 0;\n  border-style: solid;\n  border-width: 0 0 1px;\n  height: ",
        ";\n  overflow: hidden;\n  padding: 0;\n  text-align: center;\n\n  &::placeholder {\n    opacity: 0;\n  }\n\n  &:placeholder-shown {\n    border-color: ",
        ";\n  }\n\n  &:focus {\n    outline: none;\n    border-color: ",
        ";\n  }\n\n  &::-webkit-outer-spin-button,\n  &::-webkit-inner-spin-button {\n    appearance: none;\n    margin: 0;\n  }\n\n  &[type='number'] {\n    appearance: textfield;\n  }\n\n  ",
        "\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
import styled, { css } from "styled-components";
import { colors, cssfonts, pxToCssFont, pxToRem } from "@vfit/shared/themes";
var variants = {
    input: {
        sunrise: css(_templateObject(), cssfonts.exbold, pxToCssFont(24, 28), colors.$262626, pxToRem(24)),
        mva: css(_templateObject1(), cssfonts.regular, pxToCssFont(18, 28), function(param) {
            var error = param.error;
            return error ? "".concat(colors.$bd0000) : "".concat(colors.$262626);
        }, pxToRem(27), function(param) {
            var error = param.error;
            return error ? "color: ".concat(colors.$bd0000) : "";
        })
    }
};
export var Input = styled.input.withConfig({
    componentId: "sc-bf27cc9c-0"
})(_templateObject2(), pxToRem(28), colors.$bebebe, colors.$262626, function(param) {
    var variant = param.variant;
    return variant ? variants.input[variant] : "";
});
