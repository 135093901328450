import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  position: relative;\n  ",
        "\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import { cssprops } from "@vfit/shared/themes";
export var Container = styled.div.withConfig({
    componentId: "sc-58533a96-0"
})(_templateObject(), cssprops.styled);
