import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  margin: 0 25px;\n\n  @media (min-width: ",
        ") {\n    margin: 0 80px;\n  }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  flex-direction: column;\n  padding: 24px 0 40px;\n  align-self: stretch;\n\n  input {\n    border-radius: 30px;\n    width: 100%;\n  }\n\n  @media (min-width: ",
        ") {\n    flex-flow: row wrap;\n    gap: 20px;\n    align-items: baseline;\n    justify-content: center;\n  }\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n  flex-grow: 2;\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
function _templateObject3() {
    var data = _tagged_template_literal([
        "\n  .capitalize-first {\n    text-transform: lowercase;\n  }\n\n  .capitalize-first::first-letter {\n    text-transform: uppercase;\n  }\n\n  a {\n    text-decoration: unset;\n  }\n\n  button {\n    padding: 13px 15px;\n  }\n\n  @media (min-width: ",
        ") {\n    margin: auto;\n\n    button {\n      width: 280px;\n    }\n  }\n\n  @media (min-width: ",
        ") {\n    margin: unset;\n\n    button {\n      width: 200px;\n    }\n  }\n"
    ]);
    _templateObject3 = function _templateObject3() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import { breakpoints } from "@vfit/shared/themes";
export var MultiSelectionFilterContainer = styled.div.withConfig({
    componentId: "sc-e5ef741f-0"
})(_templateObject(), breakpoints.bigDesktop);
export var InputContainer = styled.div.withConfig({
    componentId: "sc-e5ef741f-1"
})(_templateObject1(), breakpoints.bigDesktop);
export var SelectInput = styled.div.withConfig({
    componentId: "sc-e5ef741f-2"
})(_templateObject2());
export var SearchButton = styled.div.withConfig({
    componentId: "sc-e5ef741f-3"
})(_templateObject3(), breakpoints.tablet, breakpoints.bigDesktop);
