import { API } from "@vfit/shared/data-access";
import { LoggerInstance, serverlessClient } from "@vfit/shared/providers";
import { useMutation } from "react-query";
/**
 * This method is used to aws lambda Vola Client - store CMB api service
 * This in used in the Vola Modal component
 * Method: POST
 *
 * @returns
 */ var storeCmbService = function(id_channel, telephone_number, contact_time, description) {
    return serverlessClient.post(API.VOLA_CLIENT, {
        id_channel: id_channel,
        data: {
            telephone_number: telephone_number,
            description: description,
            contact_time: contact_time
        },
        method: "store_cmb"
    });
};
/**
 * This method is used to instantiate a custom useMutation hook to handle the volaClientService
 * @returns
 */ export var useStoreCmbMutation = function() {
    return useMutation([
        "storeCmbMutation"
    ], function(param) {
        var id_channel = param.id_channel, telephone_number = param.telephone_number, contact_time = param.contact_time, desc = param.desc;
        return storeCmbService(id_channel, telephone_number, contact_time, desc);
    }, {
        onSuccess: function(data) {
            //queryClient.setQueryData('storeCmb', data);
            localStorage.setItem("storeCmb", JSON.stringify(data));
        },
        onError: function(error) {
            LoggerInstance.error("ERROR on storeCmb: ", error);
            //queryClient.setQueryData('storeCmb', errorMock('storeCmb', error));
            localStorage.setItem("storeCmb", JSON.stringify(error));
        }
    });
};
