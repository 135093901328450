export var organizeRateSummary = function(DataCMS) {
    return DataCMS.countryrate.elements.map(function(obj) {
        return {
            title: obj.title || "",
            rates: obj.rates.map(function(e) {
                return {
                    title: e.title || "",
                    value: e.value || ""
                };
            })
        };
    });
};
