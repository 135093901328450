import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  border: none;\n  cursor: pointer;\n  padding: 0;\n  ",
        "\n  ",
        "\n\n  &:disabled {\n    ",
        "\n  }\n\n  ",
        "\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import { cssprops } from "@vfit/shared/themes";
import * as S from "../../link.style";
export var Button = styled.button.withConfig({
    componentId: "sc-8a8f45b9-0"
})(_templateObject(), function(param) {
    var variant = param.variant;
    return variant === "regular" ? "text-decoration: underline;" : "";
}, S.common, S.disabled, cssprops.styled);
