import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  align-content: center;\n  width: ",
        ";\n  border: 1px solid #bebebe;\n  margin: 0 auto;\n  gap: 24px;\n  border-radius: 20px;\n\n  @media (min-width: ",
        ") {\n    display: flex;\n    justify-content: space-between;\n    width: 100%;\n    max-width: ",
        ";\n  }\n\n  @media (min-width: ",
        ") {\n    border-radius: 20px;\n    width: 100%;\n    max-width: ",
        ";\n  }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  padding: ",
        ";\n  @media (min-width: ",
        ") {\n    text-align: center;\n    padding: ",
        ";\n    width: 100%;\n    max-width: ",
        ";\n  }\n\n  @media (min-width: ",
        ") {\n    text-align: left;\n    width: 60%;\n    padding: ",
        ";\n  }\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  align-content: center;\n  justify-content: center;\n  background: rgb(242, 242, 242);\n  width: ",
        ";\n  border-radius: 0 20px 20px 0;\n\n  @media (min-width: ",
        ") {\n    margin-left: 0;\n  }\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
function _templateObject3() {
    var data = _tagged_template_literal([
        "\n  margin: auto;\n  width: 120%;\n  height: 100%;\n  img {\n    border-radius: 0 20px 20px 0;\n  }\n"
    ]);
    _templateObject3 = function _templateObject3() {
        return data;
    };
    return data;
}
function _templateObject4() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  margin-left: ",
        ";\n  flex-direction: column;\n  justify-content: space-around;\n  @media (min-width: ",
        ") {\n    display: flex;\n    flex-direction: row;\n    margin-top: ",
        ";\n    margin-bottom: ",
        ";\n  }\n  @media (min-width: ",
        ") {\n    display: flex;\n    flex-direction: row;\n    margin-top: ",
        ";\n    margin-bottom: ",
        ";\n    margin-left: ",
        ";\n  }\n"
    ]);
    _templateObject4 = function _templateObject4() {
        return data;
    };
    return data;
}
function _templateObject5() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  gap: 46px;\n\n  @media (min-width: ",
        ") {\n    margin-top: ",
        ";\n  }\n"
    ]);
    _templateObject5 = function _templateObject5() {
        return data;
    };
    return data;
}
function _templateObject6() {
    var data = _tagged_template_literal([
        "\n  margin-bottom: ",
        ";\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  @media (min-width: ",
        ") {\n    margin-bottom: 0;\n  }\n  img {\n    margin-right: ",
        ";\n  }\n  span {\n    @media (min-width: ",
        ") {\n      width: ",
        ";\n      height: auto;\n      align-content: center;\n      display: block;\n      height: 48px;\n      font-family: Vodafone;\n      font-size: 18px;\n      font-weight: 400;\n      line-height: 24px;\n      text-align: center;\n    }\n  }\n"
    ]);
    _templateObject6 = function _templateObject6() {
        return data;
    };
    return data;
}
function _templateObject7() {
    var data = _tagged_template_literal([
        "\n  padding-right: ",
        ";\n  display: flex;\n  padding-top: ",
        ";\n  gap: 16px;\n"
    ]);
    _templateObject7 = function _templateObject7() {
        return data;
    };
    return data;
}
function _templateObject8() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  flex-direction: column;\n  // padding-right: ",
        ";\n  padding-top: ",
        ";\n  button {\n    margin-bottom: 16px;\n  }\n"
    ]);
    _templateObject8 = function _templateObject8() {
        return data;
    };
    return data;
}
function _templateObject9() {
    var data = _tagged_template_literal([
        "\n  padding-right: ",
        ";\n  display: flex;\n  padding-top: ",
        ";\n  gap: 24px;\n  align-items: center;\n  justify-content: center;\n"
    ]);
    _templateObject9 = function _templateObject9() {
        return data;
    };
    return data;
}
function _templateObject10() {
    var data = _tagged_template_literal([
        "\n  color: ",
        ";\n  font-family: Vodafone;\n  font-size: 18px;\n  font-weight: 400;\n  line-height: 24px;\n  margin-top: ",
        ";\n  margin-bottom: ",
        ";\n  padding-right: ",
        ";\n\n  @media (min-width: ",
        ") {\n    margin-top: ",
        ";\n    margin-bottom: ",
        ";\n    display: block;\n  }\n"
    ]);
    _templateObject10 = function _templateObject10() {
        return data;
    };
    return data;
}
function _templateObject11() {
    var data = _tagged_template_literal([
        "\n  p {\n    b {\n      ",
        "\n    }\n    ",
        "\n  }\n\n  p {\n    margin: 0;\n  }\n\n  @media (min-width: ",
        ") {\n    ",
        "\n  }\n"
    ]);
    _templateObject11 = function _templateObject11() {
        return data;
    };
    return data;
}
function _templateObject12() {
    var data = _tagged_template_literal([
        "\n  margin: 25px;\n  margin-top: 50px;\n  margin-bottom: 120px;\n"
    ]);
    _templateObject12 = function _templateObject12() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import { breakpoints, colors, getTypography, pxToCssFont, pxToRem } from "@vfit/shared/themes";
export var Container = styled.div.withConfig({
    componentId: "sc-8e29f390-0"
})(_templateObject(), pxToRem(327), breakpoints.tablet, pxToRem(1280), breakpoints.desktop, pxToRem(1280));
export var DescriptionCard = styled.div.withConfig({
    componentId: "sc-8e29f390-1"
})(_templateObject1(), pxToRem(24), breakpoints.tablet, pxToRem(24), pxToRem(1280), breakpoints.desktop, pxToRem(64, 0, 64, 80));
export var QRCodeCard = styled.div.withConfig({
    componentId: "sc-8e29f390-2"
})(_templateObject2(), pxToRem(405), breakpoints.tablet);
export var QRCode = styled.div.withConfig({
    componentId: "sc-8e29f390-3"
})(_templateObject3());
export var StoreContainer = styled.div.withConfig({
    componentId: "sc-8e29f390-4"
})(_templateObject4(), pxToRem(0), breakpoints.tablet, pxToRem(24), pxToRem(24), breakpoints.desktop, pxToRem(32), pxToRem(32), pxToRem(0));
export var PlatformContainer = styled.div.withConfig({
    componentId: "sc-8e29f390-5"
})(_templateObject5(), breakpoints.desktop, pxToRem(30));
export var Store = styled.div.withConfig({
    componentId: "sc-8e29f390-6"
})(_templateObject6(), pxToRem(24), breakpoints.tablet, pxToRem(5), breakpoints.tablet, pxToRem(180));
export var ButtonContainerDesktop = styled.div.withConfig({
    componentId: "sc-8e29f390-7"
})(_templateObject7(), pxToRem(20), pxToRem(20));
export var ButtonContainerMobile = styled.div.withConfig({
    componentId: "sc-8e29f390-8"
})(_templateObject8(), pxToRem(20), pxToRem(24));
export var ButtonContainerTablet = styled.div.withConfig({
    componentId: "sc-8e29f390-9"
})(_templateObject9(), pxToRem(20), pxToRem(24));
export var DisclaimerContainer = styled.div.withConfig({
    componentId: "sc-8e29f390-10"
})(_templateObject10(), colors.$0d0d0d, pxToRem(24), pxToRem(20), pxToRem(20), breakpoints.tablet, pxToRem(24), pxToRem(0));
export var Title = styled.div.withConfig({
    componentId: "sc-8e29f390-11"
})(_templateObject11(), getTypography("h4.extrabold"), getTypography("h4.light"), breakpoints.desktop, pxToCssFont(36, 48));
export var MainContainer = styled.div.withConfig({
    componentId: "sc-8e29f390-12"
})(_templateObject12());
