import { getDay, getTimeRange } from "@vfit/shared/hooks";
import { useMemo } from "react";
export var useFrameDateInput = function(param) {
    var endDateTime = param.endDateTime, startDateTime = param.startDateTime, variant = param.variant;
    var day = useMemo(function() {
        return getDay(startDateTime);
    }, [
        getDay,
        startDateTime
    ]);
    var timeRange = useMemo(function() {
        return getTimeRange(startDateTime, endDateTime);
    }, [
        endDateTime,
        startDateTime
    ]);
    var frameInputVariant = useMemo(function() {
        return variant === "long" ? "tertiary" : "secondary";
    }, [
        variant
    ]);
    return {
        day: day,
        frameInputVariant: frameInputVariant,
        timeRange: timeRange
    };
};
