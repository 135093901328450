import { useCallback, useId, useMemo, useRef } from "react";
import { purify } from "@vfit/shared/themes";
import { useAnimation } from "@vfit/shared/hooks";
export var useOtpInput = function(param) {
    var _error = param._error, disabled = param.disabled, numbers = param.numbers, ref = param.ref;
    var innerRef = useRef(null);
    var focusedRef = useRef(false);
    var id = useId();
    var validNumbers = numbers && numbers > 0 ? numbers : 6;
    var cells = useMemo(function() {
        var c = [];
        for(var i = 0; i < validNumbers; i += 1){
            var uuid = "".concat(id, "-").concat(i);
            c.push(uuid);
        }
        return c;
    }, [
        validNumbers
    ]);
    var error = useMemo(function() {
        return _error && !disabled ? _error : "";
    }, [
        disabled,
        _error
    ]);
    var errorA = useAnimation({
        show: typeof error === "string" && !!error,
        thingsToWait: error
    });
    var errorADelayedThings = useMemo(function() {
        return purify([
            errorA.delayedThings && typeof errorA.delayedThings === "string" ? errorA.delayedThings : "", 
        ])[0] || "";
    }, [
        errorA.delayedThings
    ]);
    var refCallback = useCallback(function(node) {
        if (node) {
            innerRef.current = node;
            if (ref) {
                if (typeof ref === "function") {
                    ref(node);
                } else {
                    // eslint-disable-next-line no-param-reassign
                    ref.current = node;
                }
            }
        }
    }, [
        ref
    ]);
    return {
        cells: cells,
        errorA: errorA,
        errorADelayedThings: errorADelayedThings,
        error: error,
        focusedRef: focusedRef,
        id: id,
        innerRef: innerRef,
        refCallback: refCallback
    };
};
