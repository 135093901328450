import _async_to_generator from "@swc/helpers/src/_async_to_generator.mjs";
import regeneratorRuntime from "/home/jenkins/agent/workspace/web-sunrise-noprod/web-sunrise-consumer-deploy-dev/node_modules/next/dist/compiled/regenerator-runtime/runtime.js";
var ref;
import { Loader } from "@googlemaps/js-api-loader";
/** MAPS SERVICE
 *  It creates an instance of Loader from Google Maps Javascript API server
 */ export var loaderObj = {
    apiKey: (ref = process.env) === null || ref === void 0 ? void 0 : ref["NX_GOOGLE_MAPS_API_KEY"],
    version: "weekly",
    libraries: [
        "places"
    ],
    language: "it",
    region: "it"
};
var stringFields = {
    city: "administrative_area_level_3",
    postalCode: "postal_code",
    stateOrProvince: "administrative_area_level_2",
    street: "route",
    streetNumber: "street_number",
    region: "administrative_area_level_1"
};
var addressFilterFunction = function(detailsAddress, selector) {
    var // TODO: [refactoring]: prevedere nome piu parlante al posto di x
    ref;
    return ((ref = detailsAddress.find(function(x) {
        return x.types.includes(stringFields["".concat(selector)]);
    })) === null || ref === void 0 ? void 0 : ref.short_name) || "";
};
/** RETRIEVE ADDRESS FIELD
 * @description Helper function that receives GeocoderResponse object to be destructured
 * in an address object
 * @param -
 * - address: (google.maps.GeocoderResponse) --> input to be destructured
 * @returns an object in which you have all address object fields
 * @author Andrea
 */ var retireveAddressFields = function(address) {
    var detailsAddress = address.results[0].address_components;
    // LoggerInstance.debug(address.results[0].geometry.location.lat())
    // LoggerInstance.debug(address.results[0].geometry.location.lng())
    // TODO --> MAPPING WITH FUNCTION
    var placeDetails = {
        city: addressFilterFunction(detailsAddress, "city"),
        postalCode: addressFilterFunction(detailsAddress, "postalCode"),
        stateOrProvince: addressFilterFunction(detailsAddress, "stateOrProvince"),
        street: addressFilterFunction(detailsAddress, "street"),
        streetNumber: addressFilterFunction(detailsAddress, "streetNumber"),
        region: addressFilterFunction(detailsAddress, "region"),
        latitude: address.results[0].geometry.location.lat().toString(),
        longitude: address.results[0].geometry.location.lng().toString(),
        placeId: address.results[0].place_id
    };
    return placeDetails;
};
/** CALL-AUTOCOMPLETE
 * @description It calls autocomplete service to use getPlacePredictions from an
 * input string.
 * @param -
 * - input: (string) --> argument to be used in getPlacePredictions method
 * @returns null array if no predictions available, an array of predictions otherwise
 * @author Andrea
 */ export var callAutocomplete = function() {
    var _ref = _async_to_generator(regeneratorRuntime.mark(function _callee(input) {
        var mapsLoader, autocompleteService, service, response;
        return regeneratorRuntime.wrap(function _callee$(_ctx) {
            while(1)switch(_ctx.prev = _ctx.next){
                case 0:
                    mapsLoader = new Loader(loaderObj);
                    autocompleteService = mapsLoader.load().then(function(google) {
                        return google && new google.maps.places.AutocompleteService();
                    });
                    _ctx.next = 4;
                    return autocompleteService;
                case 4:
                    service = _ctx.sent;
                    _ctx.next = 7;
                    return service.getPlacePredictions({
                        input: input,
                        componentRestrictions: {
                            country: "it"
                        },
                        types: [
                            "address"
                        ]
                    });
                case 7:
                    response = _ctx.sent;
                    return _ctx.abrupt("return", response.predictions);
                case 9:
                case "end":
                    return _ctx.stop();
            }
        }, _callee);
    }));
    return function callAutocomplete(input) {
        return _ref.apply(this, arguments);
    };
}();
// TODO --> POSSIBILITY TO PUT TOGETHER
/** USE-PLACEID
 * @description It calls geocoder service to use geocode method from an
 * input placeId.
 * It uses retrieveAddressField() helper function to destructure the response object
 * @param placeId
 * - placeId: (string) --> argument to be used in geocode method
 * @returns an object in which you have all address object fields
 * @author Andrea
 */ export var getPlaceId = function() {
    var _ref = _async_to_generator(regeneratorRuntime.mark(function _callee(placeId) {
        var mapsLoader, google, geocoder, details;
        return regeneratorRuntime.wrap(function _callee$(_ctx) {
            while(1)switch(_ctx.prev = _ctx.next){
                case 0:
                    mapsLoader = new Loader(loaderObj);
                    _ctx.next = 3;
                    return mapsLoader.load();
                case 3:
                    google = _ctx.sent;
                    geocoder = google && new google.maps.Geocoder();
                    _ctx.next = 7;
                    return geocoder.geocode({
                        placeId: placeId
                    });
                case 7:
                    details = _ctx.sent;
                    if (!details) {
                        _ctx.next = 10;
                        break;
                    }
                    return _ctx.abrupt("return", retireveAddressFields(details));
                case 10:
                    return _ctx.abrupt("return", null);
                case 11:
                case "end":
                    return _ctx.stop();
            }
        }, _callee);
    }));
    return function getPlaceId(placeId) {
        return _ref.apply(this, arguments);
    };
}();
/** USE-ADDRESSGEOCODER
 * @description It calls geocoder service to use geocode method from an
 * input text.
 * It uses retrieveAddressField() helper function to destructure the response object
 * @param -
 * - text: (string) --> argument to be used in geocode method
 * @returns an object in which you have all address object fields
 * @author Andrea
 */ export var useAddressGeocoder = function() {
    var _ref = _async_to_generator(regeneratorRuntime.mark(function _callee(text) {
        var mapsLoader, google, geocoder, details;
        return regeneratorRuntime.wrap(function _callee$(_ctx) {
            while(1)switch(_ctx.prev = _ctx.next){
                case 0:
                    if (!(text === undefined)) {
                        _ctx.next = 2;
                        break;
                    }
                    return _ctx.abrupt("return", undefined);
                case 2:
                    mapsLoader = new Loader(loaderObj);
                    _ctx.next = 5;
                    return mapsLoader.load();
                case 5:
                    google = _ctx.sent;
                    geocoder = google && new google.maps.Geocoder();
                    _ctx.next = 9;
                    return geocoder.geocode({
                        address: text
                    });
                case 9:
                    details = _ctx.sent;
                    if (!details) {
                        _ctx.next = 12;
                        break;
                    }
                    return _ctx.abrupt("return", retireveAddressFields(details));
                case 12:
                    return _ctx.abrupt("return", null);
                case 13:
                case "end":
                    return _ctx.stop();
            }
        }, _callee);
    }));
    return function useAddressGeocoder(text) {
        return _ref.apply(this, arguments);
    };
}();
