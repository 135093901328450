import { API } from "@vfit/shared/data-access";
import { LoggerInstance, serverlessClient } from "@vfit/shared/providers";
import { useMutation } from "react-query";
/**
 * This method is used to aws lambda Video Recognition api service
 * and retrieve the sim session.
 * This in used in the VRLanding component
 * Method: POST
 *
 * @returns
 */ var videoRecognitionService = function(simSerial) {
    return serverlessClient.post(API.VIDEO_RECOGNITION, {
        sim_serial: simSerial
    });
};
/**
 * This method is used to instantiate a custom useMutation hook to handle the videoRecognitionService
 * @returns
 */ export var useVideoRecognitionMutation = function() {
    return useMutation([
        "videoRecognition"
    ], function(simSerial) {
        return videoRecognitionService(simSerial);
    }, {
        onSuccess: function(data) {
            localStorage.setItem("videoRecognition", JSON.stringify(data));
        },
        onError: function(error) {
            LoggerInstance.error("ERROR on videoRecognition: ", error);
            localStorage.setItem("videoRecognition", JSON.stringify(error));
        }
    });
};
