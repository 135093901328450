import { purify } from "@vfit/shared/themes";
export var organizeClickToCall = function(cmsData) {
    var ref, ref1, ref2, ref3, ref4, ref5, ref6, ref7, ref8, ref9, ref10, ref11, ref12, ref13, ref14, ref15, ref16, ref17, ref18, ref19, ref20, ref21, ref22, ref23;
    return {
        title: ((ref2 = purify([
            (cmsData === null || cmsData === void 0 ? void 0 : (ref = cmsData.clicktocall) === null || ref === void 0 ? void 0 : (ref1 = ref.toSubmit) === null || ref1 === void 0 ? void 0 : ref1.title) || ""
        ])) === null || ref2 === void 0 ? void 0 : ref2[0]) || "",
        description: ((ref5 = purify([
            (cmsData === null || cmsData === void 0 ? void 0 : (ref3 = cmsData.clicktocall) === null || ref3 === void 0 ? void 0 : (ref4 = ref3.toSubmit) === null || ref4 === void 0 ? void 0 : ref4.description) || ""
        ])) === null || ref5 === void 0 ? void 0 : ref5[0]) || "",
        descriptionCallMeNow: ((ref8 = purify([
            (cmsData === null || cmsData === void 0 ? void 0 : (ref6 = cmsData.clicktocall) === null || ref6 === void 0 ? void 0 : (ref7 = ref6.toSubmit) === null || ref7 === void 0 ? void 0 : ref7.descriptionCallMeNow) || ""
        ])) === null || ref8 === void 0 ? void 0 : ref8[0]) || "",
        phoneNumber: ((ref11 = purify([
            (cmsData === null || cmsData === void 0 ? void 0 : (ref9 = cmsData.clicktocall) === null || ref9 === void 0 ? void 0 : (ref10 = ref9.toSubmit) === null || ref10 === void 0 ? void 0 : ref10.phoneNumber) || ""
        ])) === null || ref11 === void 0 ? void 0 : ref11[0]) || "",
        callMeBack: ((ref14 = purify([
            (cmsData === null || cmsData === void 0 ? void 0 : (ref12 = cmsData.clicktocall) === null || ref12 === void 0 ? void 0 : (ref13 = ref12.toSubmit) === null || ref13 === void 0 ? void 0 : ref13.callMeBack) || ""
        ])) === null || ref14 === void 0 ? void 0 : ref14[0]) || "",
        contactTime: ((ref17 = purify([
            (cmsData === null || cmsData === void 0 ? void 0 : (ref15 = cmsData.clicktocall) === null || ref15 === void 0 ? void 0 : (ref16 = ref15.toSubmit) === null || ref16 === void 0 ? void 0 : ref16.contactTime) || ""
        ])) === null || ref17 === void 0 ? void 0 : ref17[0]) || "",
        buttonLabel: ((ref20 = purify([
            (cmsData === null || cmsData === void 0 ? void 0 : (ref18 = cmsData.clicktocall) === null || ref18 === void 0 ? void 0 : (ref19 = ref18.toSubmit) === null || ref19 === void 0 ? void 0 : ref19.buttonLabel) || ""
        ])) === null || ref20 === void 0 ? void 0 : ref20[0]) || "",
        footer: ((ref23 = purify([
            (cmsData === null || cmsData === void 0 ? void 0 : (ref21 = cmsData.clicktocall) === null || ref21 === void 0 ? void 0 : (ref22 = ref21.toSubmit) === null || ref22 === void 0 ? void 0 : ref22.footer) || ""
        ])) === null || ref23 === void 0 ? void 0 : ref23[0]) || ""
    };
};
