import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  padding: ",
        ";\n  background-color: ",
        ";\n  border: 1px solid ",
        ";\n  border-radius: 20px;\n  height: 100%;\n  width: 269px;\n  ",
        ";\n\n  @media (min-width: ",
        ") {\n    width: 328px;\n  }\n\n  @media (min-width: ",
        ") {\n    width: 404px;\n  }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  ",
        ";\n  ",
        ";\n  margin: 0;\n\n  @media (min-width: ",
        ") {\n    line-height: ",
        ";\n  }\n\n  @media (min-width: ",
        ") {\n    ",
        ";\n  }\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
import { breakpoints, colors, cssfonts, pxToCssFont, pxToLineHeight, pxToRem } from "@vfit/shared/themes";
import styled from "styled-components";
export var Article = styled.article.withConfig({
    componentId: "sc-60c3da38-0"
})(_templateObject(), pxToRem(32, 24), colors.$ffffff, colors.$bebebe, function(param) {
    var centerEditorialFrame = param.centerEditorialFrame;
    return centerEditorialFrame ? "margin: 0 auto" : "";
}, breakpoints.tablet, breakpoints.desktop);
export var Title = styled.h3.withConfig({
    componentId: "sc-60c3da38-1"
})(_templateObject1(), cssfonts.exbold, pxToCssFont(24, 28), breakpoints.tablet, pxToLineHeight(24, 30), breakpoints.desktop, pxToCssFont(36, 45));
