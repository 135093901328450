import * as yup from "yup";
export var callMeBackSchema = function() {
    return yup.object({
        phoneNumber: yup.string().required("Campo Obbligatorio").min(6).max(14),
        timeSlot: yup.string().required("Campo Obbligatorio")
    });
};
export var callMeSchema = function() {
    return yup.object({
        phoneNumber: yup.string().required("Campo Obbligatorio").min(6).max(14)
    });
};
