import { useCallback, useId, useMemo, useRef } from "react";
import groupBy from "lodash-es/groupBy";
import { createSyntheticEvent, useDeviceType } from "@vfit/shared/hooks";
export var useSlotsCalendarInput = function(param) {
    var dateTimes = param.dateTimes, onChange = param.onChange, ref = param.ref;
    var innerRef = useRef(null);
    var frameDateInputsName = useId();
    var ref1 = useDeviceType(), isMobile = ref1.isMobile, isTablet = ref1.isTablet;
    var slots = useMemo(function() {
        return Object.entries(groupBy(dateTimes, function(slot) {
            var ref;
            return new Date(slot === null || slot === void 0 ? void 0 : (ref = slot.validFor) === null || ref === void 0 ? void 0 : ref.startDateTime).toISOString().split("T")[0];
        })).sort(function(a, b) {
            return a[0] < b[0] ? -1 : a[0] > b[0] ? 1 : 0;
        });
    }, [
        dateTimes
    ]);
    var onSelect = useCallback(function(e) {
        if (e.currentTarget.checked && innerRef.current) {
            var v = e.currentTarget.value;
            // eslint-disable-next-line no-param-reassign
            innerRef.current.value = v;
            var syntheticEvent = createSyntheticEvent(innerRef.current, "change");
            if (syntheticEvent) {
                onChange === null || onChange === void 0 ? void 0 : onChange(syntheticEvent);
            }
        }
    }, [
        onChange
    ]);
    var refCallback = useCallback(function(node) {
        if (node) {
            innerRef.current = node;
            if (ref) {
                if (typeof ref === "function") {
                    ref(node);
                } else {
                    // eslint-disable-next-line no-param-reassign
                    ref.current = node;
                }
            }
        }
    }, [
        ref
    ]);
    return {
        isMobile: isMobile,
        isTablet: isTablet,
        refCallback: refCallback,
        frameDateInputsName: frameDateInputsName,
        onSelect: onSelect,
        slots: slots
    };
};
