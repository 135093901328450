import { useCallback, useRef, useState } from "react";
export var useVfSwiper = function() {
    var ref = useState(null), swiper = ref[0], setSwiper = ref[1];
    var navigationPrevRef = useRef(null);
    var navigationNextRef = useRef(null);
    var onSwiper = useCallback(function(s) {
        setSwiper(s);
    }, []);
    var slidePrev = useCallback(function() {
        swiper === null || swiper === void 0 ? void 0 : swiper.slidePrev();
    }, [
        swiper
    ]);
    var slideNext = useCallback(function() {
        swiper === null || swiper === void 0 ? void 0 : swiper.slideNext();
    }, [
        swiper
    ]);
    return {
        navigationPrevRef: navigationPrevRef,
        navigationNextRef: navigationNextRef,
        onSwiper: onSwiper,
        slidePrev: slidePrev,
        slideNext: slideNext
    };
};
