import { colors } from "@vfit/shared/themes";
export var getButtonStyle = function(variant) {
    var buttonStyle = {};
    switch(variant){
        case "primary":
            buttonStyle = {
                buttonColor: "".concat(colors.$e60000),
                hoverColor: "".concat(colors.$bd0000),
                clickColor: "".concat(colors.$a10000),
                labelColor: "".concat(colors.$ffffff),
                borderColor: "".concat(colors.$e60000)
            };
            break;
        case "secondary":
            buttonStyle = {
                buttonColor: "".concat(colors.$ffffff),
                hoverColor: "".concat(colors.$ffffff_80),
                clickColor: "".concat(colors.$ffffff_60),
                labelColor: "".concat(colors.$262626),
                borderColor: "".concat(colors.$ffffff)
            };
            break;
        case "tertiary":
            buttonStyle = {
                buttonColor: "".concat(colors.$transparent),
                hoverColor: "".concat(colors.$262626),
                clickColor: "".concat(colors.$7e7e7e),
                labelColor: "".concat(colors.$262626),
                hoverTextColor: "".concat(colors.$ffffff),
                clickTextColor: "".concat(colors.$ffffff),
                borderColor: "".concat(colors.$262626)
            };
            break;
        default:
            buttonStyle = {
                buttonColor: "".concat(colors.$e60000),
                hoverColor: "".concat(colors.$bd0000),
                clickColor: "".concat(colors.$a10000),
                labelColor: "".concat(colors.$ffffff),
                borderColor: "".concat(colors.$e60000)
            };
            break;
    }
    return buttonStyle;
};
