import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  margin-top: 32px;\n  background-color: ",
        ";\n  border-radius: 20px;\n  padding: 32px 24px;\n\n  @media (min-width: ",
        ") {\n    padding: 32px 40px;\n  }\n\n  @media (min-width: ",
        ") {\n    padding: 40px 54px;\n  }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  margin: 0;\n  font-weight: 400;\n  color: ",
        ";\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n  font-family: ",
        ";\n  ",
        "\n\n  @media (min-width: ",
        ") {\n    ",
        "\n  }\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
function _templateObject3() {
    var data = _tagged_template_literal([
        "\n  margin: 0;\n  margin-top: 24px;\n  padding: 0;\n  list-style-type: none;\n"
    ]);
    _templateObject3 = function _templateObject3() {
        return data;
    };
    return data;
}
function _templateObject4() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  align-items: baseline;\n  margin-bottom: 8px;\n\n  @media (min-width: ",
        ") {\n    margin-bottom: 16px;\n  }\n\n  &:last-child {\n    margin-bottom: 0;\n  }\n"
    ]);
    _templateObject4 = function _templateObject4() {
        return data;
    };
    return data;
}
function _templateObject5() {
    var data = _tagged_template_literal([
        "\n  font-family: ",
        ";\n  ",
        "\n  font-weight: 400;\n  color: ",
        ";\n\n  @media (min-width: ",
        ") {\n    ",
        "\n  }\n"
    ]);
    _templateObject5 = function _templateObject5() {
        return data;
    };
    return data;
}
function _templateObject6() {
    var data = _tagged_template_literal([
        "\n  margin-left: 16px;\n\n  p {\n    margin: 0;\n  }\n\n  div {\n    margin: 0;\n    font-family: ",
        ";\n    ",
        "\n    text-align: left;\n  }\n"
    ]);
    _templateObject6 = function _templateObject6() {
        return data;
    };
    return data;
}
function _templateObject7() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n\n  .linkWithIcon {\n    margin-top: 8px;\n  }\n"
    ]);
    _templateObject7 = function _templateObject7() {
        return data;
    };
    return data;
}
function _templateObject8() {
    var data = _tagged_template_literal([
        "\n  padding: 32px 28px;\n\n  @media (min-width: ",
        ") {\n    padding: 0;\n  }\n"
    ]);
    _templateObject8 = function _templateObject8() {
        return data;
    };
    return data;
}
function _templateObject9() {
    var data = _tagged_template_literal([
        "\n  margin-top: 32px;\n\n  div {\n    margin: 0;\n    font-family: ",
        ";\n    ",
        "\n    text-align: left;\n  }\n"
    ]);
    _templateObject9 = function _templateObject9() {
        return data;
    };
    return data;
}
function _templateObject10() {
    var data = _tagged_template_literal([
        "\n  margin-top: 14px;\n"
    ]);
    _templateObject10 = function _templateObject10() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import { breakpoints, colors, fonts, pxToCssFont } from "@vfit/shared/themes";
export var Container = styled.div.withConfig({
    componentId: "sc-4cfee584-0"
})(_templateObject(), colors.$ffffff, breakpoints.tablet, breakpoints.desktop);
export var Paragraph = styled.p.withConfig({
    componentId: "sc-4cfee584-1"
})(_templateObject1(), colors.$262626);
export var Title = styled(Paragraph).withConfig({
    componentId: "sc-4cfee584-2"
})(_templateObject2(), fonts.exbold, pxToCssFont(30, 38), breakpoints.desktop, pxToCssFont(36, 45));
export var StepsList = styled.ul.withConfig({
    componentId: "sc-4cfee584-3"
})(_templateObject3());
export var Step = styled.li.withConfig({
    componentId: "sc-4cfee584-4"
})(_templateObject4(), breakpoints.tablet);
export var NumberBox = styled.div.withConfig({
    componentId: "sc-4cfee584-5"
})(_templateObject5(), fonts.regular, pxToCssFont(24, 28), colors.$e60000, breakpoints.tablet, pxToCssFont(24, 30));
export var Description = styled(Paragraph).withConfig({
    componentId: "sc-4cfee584-6"
})(_templateObject6(), fonts.regular, pxToCssFont(18, 24));
export var LinkContainer = styled.div.withConfig({
    componentId: "sc-4cfee584-7"
})(_templateObject7());
export var ModalInfoContainer = styled.div.withConfig({
    componentId: "sc-4cfee584-8"
})(_templateObject8(), breakpoints.tablet);
export var FirstContent = styled.div.withConfig({
    componentId: "sc-4cfee584-9"
})(_templateObject9(), fonts.regular, pxToCssFont(18, 24));
export var SecondContent = styled(FirstContent).withConfig({
    componentId: "sc-4cfee584-10"
})(_templateObject10());
